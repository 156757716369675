import React from 'react';
import PropTypes from 'prop-types';
import TEAM_PROP_TYPES from '../../../lib/prop-types/team';
import ClubSponsors from './club-sponsors';
import LeagueSponsor from './league-sponsor';

export const shouldLeagueBannerShow = (pageType, team) => {
  if (pageType === 'homepage.index') {
    return true;
  }

  if (pageType === 'team.fixtures') {
    // First team fixtures
    return team.rank === 0;
  }

  return false;
};

const SponsorStrip = ({ leagueSponsor, pageType, sponsors, team }) => (
  <>
    {leagueSponsor && shouldLeagueBannerShow(pageType, team) && (
      <LeagueSponsor sponsor={leagueSponsor} />
    )}
    {sponsors.length > 0 && <ClubSponsors sponsors={sponsors} />}
  </>
);

SponsorStrip.propTypes = {
  leagueSponsor: PropTypes.shape({}),
  pageType: PropTypes.string,
  sponsors: PropTypes.arrayOf(PropTypes.shape()),
  team: TEAM_PROP_TYPES,
};

SponsorStrip.defaultProps = {
  leagueSponsor: null,
  pageType: '',
  sponsors: [],
  team: null,
};

export default SponsorStrip;
