import React from 'react';
import PropTypes from 'prop-types';

const TokenRedirectLink = ({ children, className, href }) => (
  <a href={`/token-redirect?url=${encodeURIComponent(href)}`} className={className}>
    {children}
  </a>
);

TokenRedirectLink.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]).isRequired,
  href: PropTypes.string.isRequired,
};

TokenRedirectLink.defaultProps = {
  className: null,
};

export default TokenRedirectLink;
