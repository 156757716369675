import React from 'react';
import PropTypes from 'prop-types';
import { FlexContainer, Typography } from '@pitchero/react-ui';
import { Trans } from '../../../../lib/i18n';

const Location = ({ location, isMobile }) => (
  <Typography
    preset="description"
    color="primaryContrast"
    size={isMobile ? 14 : 16}
    align={isMobile ? 'center' : 'left'}
  >
    <FlexContainer flexDirection="column" style={{ gap: '20px' }}>
      <FlexContainer flexDirection="column" style={{ gap: '4px' }}>
        <span>{location.address1 ?? ''}</span>
        <span>{location.address2 ?? ''}</span>
        <span>{location.address3 ?? ''}</span>
        <span>{location.address4 ?? ''}</span>
        <span>{location.postcode ?? ''}</span>
      </FlexContainer>
      <span>{location.telephone ? `Tel: ${location.telephone}` : ''}</span>
      {location.map_url_location && (
        <a
          href={location.map_url_location}
          target="_blank"
          style={{ fontWeight: 'bold', textTransform: 'uppercase' }}
          rel="noreferrer"
        >
          <Trans i18nKey="common:view_on_google_maps">View on google maps</Trans>
        </a>
      )}
    </FlexContainer>
  </Typography>
);

Location.propTypes = {
  location: PropTypes.shape({
    address1: PropTypes.string.isRequired,
    address2: PropTypes.string,
    address3: PropTypes.string,
    address4: PropTypes.string,
    country: PropTypes.string,
    map_url_directions: PropTypes.string,
    map_url_location: PropTypes.string,
    postcode: PropTypes.string,
    telephone: PropTypes.string,
  }).isRequired,
  isMobile: PropTypes.bool.isRequired,
};

export default Location;
