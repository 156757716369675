import isServer from './is-server';

export const getVariable = (variable, defaultValue = null) => {
  const value = isServer() ? process.env[variable] : window[variable];
  return value || defaultValue;
};

export const appDomain = () => getVariable('APP_DOMAIN', 'www.pitchero.com').toLowerCase();

export const pitcheroDomain = () => getVariable('PITCHERO_DOMAIN');

export const marketingAssestsDomain = () =>
  getVariable('MARKETING_ASSETS_DOMAIN', 'www.pitchero.com');

export const environment = () => getVariable('NODE_ENV');

export const isProduction = () => environment() === 'production';

export const mapBoxKey = () => getVariable('MAPBOX_API_KEY');

export const recaptchaV3Key = () => getVariable('GOOGLE_RECAPTCHA_V3_PUBLIC_KEY');
