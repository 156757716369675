import React from 'react';
import PropTypes from 'prop-types';
import { Cushion, FlexContainer, Hide, MaxWidth, Rectangle } from '@pitchero/react-ui';
import Sponsor from '../../sponsors/sponsor';

const ClubSponsors = ({ sponsors }) => (
  <Hide maxWidth="tab">
    <Rectangle fill="white" component="div">
      <Cushion all="small">
        <MaxWidth component="div" maxWidth={1366} center>
          <FlexContainer flexDirection="row" justifyContent="space-around">
            {sponsors.map((sponsor) => (
              <Sponsor key={sponsor.id} {...sponsor} placement="header" />
            ))}
          </FlexContainer>
        </MaxWidth>
      </Cushion>
    </Rectangle>
  </Hide>
);

ClubSponsors.propTypes = {
  sponsors: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      image: PropTypes.string,
      type: PropTypes.string,
      url: PropTypes.string,
    }),
  ).isRequired,
};

export default ClubSponsors;
