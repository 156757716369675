import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { ImageResizer } from '@pitchero/react-ui';
import { isEmpty } from 'lodash';

function getRetinaUrls(
  includeRetina,
  { backgroundColor, height, quality, src, transformation, width },
) {
  if (!includeRetina || isEmpty(src)) {
    return null;
  }

  const standardImageUrl = ImageResizer.resizeUrl(src, {
    bg: backgroundColor,
    h: height,
    w: width,
    t: transformation,
    q: quality,
  });

  const midImageUrl = ImageResizer.resizeUrl(src, {
    bg: backgroundColor,
    h: height * 1.5,
    w: width * 1.5,
    t: transformation,
    q: quality,
  });

  const retinaImageUrl = ImageResizer.resizeUrl(src, {
    bg: backgroundColor,
    h: height * 2,
    w: width * 2,
    t: transformation,
    q: quality,
  });

  return `${standardImageUrl} 1x, ${midImageUrl} 1.5x, ${retinaImageUrl} 2x`;
}

const BasicImage = forwardRef(
  (
    {
      alt,
      backgroundColor,
      className,
      height,
      includeRetina,
      quality,
      src,
      style,
      transformation,
      width,
    },
    ref,
  ) => (
    <img
      alt={alt}
      className={className}
      height={height}
      ref={ref}
      src={
        isEmpty(src)
          ? null
          : ImageResizer.resizeUrl(src, {
              bg: backgroundColor,
              h: height,
              w: width,
              t: transformation,
              q: quality,
            })
      }
      srcSet={getRetinaUrls(includeRetina, {
        src,
        backgroundColor,
        height,
        width,
        transformation,
        quality,
      })}
      style={{
        height: 'auto',
        maxWidth: '100%',
        ...style,
      }}
      width={width}
    />
  ),
);

BasicImage.defaultProps = {
  backgroundColor: null,
  className: null,
  includeRetina: false,
  quality: null,
  transformation: 'fit',
  style: null,
};

BasicImage.propTypes = {
  alt: PropTypes.string.isRequired,
  backgroundColor: PropTypes.string,
  className: PropTypes.string,
  height: PropTypes.number.isRequired,
  includeRetina: PropTypes.bool,
  quality: PropTypes.number,
  src: PropTypes.string.isRequired,
  style: PropTypes.shape(),
  transformation: PropTypes.string,
  width: PropTypes.number.isRequired,
};

export default BasicImage;
