import { combineReducers } from 'redux';
import { pickBy, uniqBy } from 'lodash';
import {
  CALENDAR_REQUEST,
  CALENDAR_RECEIVE,
  CALENDAR_FAILURE,
  TRAINING_SESSION_REQUEST,
  TRAINING_SESSION_RECEIVE,
  TRAINING_SESSION_FAILURE,
  TRAINING_AVAILABILITY_REQUEST,
  TRAINING_AVAILABILITY_RECEIVE,
  TRAINING_AVAILABILITY_FAILURE,
  SET_AVAILABILITY_REQUEST,
} from '../action-types';

const isLoadingCalendar = (state = false, action) => {
  switch (action.type) {
    case CALENDAR_REQUEST:
      return true;
    case CALENDAR_RECEIVE:
    case CALENDAR_FAILURE:
      return false;
    default:
      return state;
  }
};

const loadedCalendarIds = (state = [], action) => {
  switch (action.type) {
    case CALENDAR_RECEIVE: {
      const { clubId, year, month } = action.payload;
      return [...state, [clubId, year, month].join('-')];
    }
    default:
      return state;
  }
};

const currentlyLoaded = (state = {}, action) => {
  switch (action.type) {
    case CALENDAR_RECEIVE: {
      const { clubId, year, month } = action.payload;
      const id = [clubId, year, month].join('-');
      return {
        ...state,
        [id]: action.payload.calendar,
      };
    }
    default:
      return state;
  }
};

const loadingError = (state = null, action) => {
  switch (action.type) {
    case CALENDAR_FAILURE: {
      return action.payload;
    }
    case CALENDAR_REQUEST:
    case CALENDAR_RECEIVE:
      return null;
    default:
      return state;
  }
};

const loadingTrainingSessions = (state = [], action) => {
  switch (action.type) {
    case TRAINING_SESSION_REQUEST: {
      const { sessionId } = action.payload;
      return [...state, sessionId];
    }
    case TRAINING_SESSION_RECEIVE:
    case TRAINING_SESSION_FAILURE: {
      const { sessionId } = action.payload;
      return [...state].filter((id) => id !== sessionId);
    }
    default:
      return state;
  }
};

const loadedTrainingSessions = (state = [], action) => {
  switch (action.type) {
    case TRAINING_SESSION_RECEIVE: {
      const { sessionId } = action.payload;
      return [...state, sessionId];
    }
    default:
      return state;
  }
};

const trainingSessions = (state = [], action) => {
  switch (action.type) {
    case TRAINING_SESSION_RECEIVE: {
      const { session } = action.payload;
      return uniqBy([session, ...state], 'id');
    }
    default:
      return state;
  }
};

const trainingLoadingErrors = (state = {}, action) => {
  switch (action.type) {
    case TRAINING_SESSION_FAILURE: {
      const { sessionId } = action.payload;
      return {
        ...state,
        [sessionId]: action.payload,
      };
    }
    case TRAINING_SESSION_REQUEST:
    case TRAINING_SESSION_RECEIVE: {
      const { sessionId } = action.payload;
      return pickBy(
        {
          ...state,
        },
        (err) => err.sessionId !== sessionId,
      );
    }
    default:
      return state;
  }
};

const loadingTrainingAvailabilities = (state = [], action) => {
  switch (action.type) {
    case TRAINING_AVAILABILITY_REQUEST: {
      const { sessionId } = action.payload;
      return [...state, sessionId];
    }
    case TRAINING_AVAILABILITY_RECEIVE:
    case TRAINING_AVAILABILITY_FAILURE: {
      const { sessionId } = action.payload;
      return [...state].filter((id) => id !== sessionId);
    }
    default:
      return state;
  }
};

const loadedTrainingAvailabilities = (state = [], action) => {
  switch (action.type) {
    case TRAINING_AVAILABILITY_RECEIVE: {
      const { sessionId } = action.payload;
      return [...state, sessionId];
    }
    default:
      return state;
  }
};

const updateAvailabilityOnSessions = (state, date, memberId, status, note) => {
  const sessionsOnDate = [...state].filter((session) => session.date === date);
  const updatedSessions = sessionsOnDate.map((session) => {
    const { memberAvailabilities } = session;
    const updatedAvailabilities = memberAvailabilities.map((memberAvailability) => {
      if (memberAvailability.memberId === memberId) {
        return {
          ...memberAvailability,
          status,
          note,
        };
      }

      return memberAvailability;
    });

    return {
      ...session,
      memberAvailabilities: updatedAvailabilities,
    };
  });

  return uniqBy([...updatedSessions, ...state], 'id');
};

const trainingAvailabilities = (state = [], action) => {
  switch (action.type) {
    case TRAINING_AVAILABILITY_RECEIVE: {
      const { data } = action.payload;
      return uniqBy([data, ...state], 'id');
    }
    case SET_AVAILABILITY_REQUEST: {
      const { date, memberId, status, note } = action.payload;
      return updateAvailabilityOnSessions(state, date, memberId, status, note);
    }
    default:
      return state;
  }
};

const trainingAvailabilityLoadingErrors = (state = {}, action) => {
  switch (action.type) {
    case TRAINING_AVAILABILITY_FAILURE: {
      const { sessionId, error } = action.payload;
      return {
        ...state,
        [sessionId]: error,
      };
    }
    case TRAINING_AVAILABILITY_REQUEST:
    case TRAINING_AVAILABILITY_RECEIVE: {
      const { sessionId } = action.payload;
      return pickBy(
        {
          ...state,
        },
        (err) => err.sessionId !== sessionId,
      );
    }
    default:
      return state;
  }
};

export default combineReducers({
  isLoadingCalendar,
  currentlyLoaded,
  loadedCalendarIds,
  loadingError,
  loadingTrainingSessions,
  loadedTrainingSessions,
  trainingLoadingErrors,
  trainingSessions,
  loadingTrainingAvailabilities,
  loadedTrainingAvailabilities,
  trainingAvailabilities,
  trainingAvailabilityLoadingErrors,
});
