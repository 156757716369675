import { combineReducers } from 'redux';
import { reject } from 'lodash';
import {
  NEWS_ARTICLE_REQUEST,
  NEWS_ARTICLE_RECEIVE,
  NEWS_ARTICLE_FAILURE,
  NEWS_PAGE_FAILURE,
  NEWS_PAGE_REQUEST,
} from '../action-types';

const loadingArticles = (state = [], action) => {
  switch (action.type) {
    case NEWS_ARTICLE_REQUEST: {
      return [...state, action.payload.articleId];
    }
    case NEWS_ARTICLE_RECEIVE:
    case NEWS_ARTICLE_FAILURE: {
      const articleIds = [...state];
      return reject(articleIds, (loadingId) => loadingId === action.payload.articleId);
    }
    default:
      return state;
  }
};

const hasAttemptedLoad = (state = [], action) => {
  switch (action.type) {
    case NEWS_ARTICLE_RECEIVE:
    case NEWS_ARTICLE_FAILURE: {
      return [...state, action.payload.articleId];
    }
    default:
      return state;
  }
};

const articles = (state = [], action) => {
  switch (action.type) {
    case NEWS_ARTICLE_RECEIVE: {
      return [...state, action.payload.article];
    }
    default:
      return state;
  }
};

const newsPageLoadingError = (state = null, action) => {
  switch (action.type) {
    case NEWS_PAGE_REQUEST:
      return null;
    case NEWS_PAGE_FAILURE: {
      return action.payload;
    }
    default:
      return state;
  }
};

const newsArticlePageLoadingError = (state = null, action) => {
  switch (action.type) {
    case NEWS_ARTICLE_REQUEST:
    case NEWS_ARTICLE_RECEIVE: {
      const { articleId } = action.payload;
      return {
        ...state,
        [articleId]: null,
      };
    }
    case NEWS_ARTICLE_FAILURE: {
      const { articleId, clubId, statusCode, error } = action.payload;
      return {
        ...state,
        [articleId]: {
          clubId,
          statusCode,
          error,
        },
      };
    }
    default:
      return state;
  }
};

const loadedArticles = (state = [], action) => {
  switch (action.type) {
    case NEWS_ARTICLE_RECEIVE:
      return [...state, action.payload.articleId];
    default:
      return state;
  }
};

export default combineReducers({
  loadingArticles,
  hasAttemptedLoad,
  newsPageLoadingError,
  newsArticlePageLoadingError,
  articles,
  loadedArticles,
});
