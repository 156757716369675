import button from '../club-theme/button';
import typography from '../club-theme/typography';
import spacing from '../club-theme/spacing';
import shadows from '../club-theme/shadows';
import breakpoints from '../club-theme/breakpoints';
import colors from './colors';

export default {
  button: button(colors),
  colors,
  typography,
  spacing,
  shadows,
  breakpoints,
};
