import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { Typography, withTheme } from '@pitchero/react-ui';

const NotificationBadge = ({
  backgroundColor,
  color,
  typographyPreset,
  size,
  borderColor,
  style: customStyle,
  total,
  uiTheme,
  ...otherProps
}) => {
  if (total <= 0) {
    return null;
  }

  const style = {
    color: get(uiTheme, `colors.${color}`, '#FFFFFF'),
    background: get(uiTheme, `colors.${backgroundColor}`, '#00a0d1'),
    height: borderColor ? size + 2 : size,
    width: borderColor ? size + 2 : size,
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: borderColor ? `solid 2px ${borderColor}` : null,
    ...customStyle,
  };

  return (
    <span style={{ display: 'inline-block' }} {...otherProps}>
      <Typography preset={typographyPreset} style={style}>
        {total}
      </Typography>
    </span>
  );
};

NotificationBadge.propTypes = {
  backgroundColor: PropTypes.string,
  borderColor: PropTypes.string,
  color: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  total: PropTypes.number.isRequired,
  typographyPreset: PropTypes.string,
  style: PropTypes.shape(),
  uiTheme: PropTypes.shape(),
};

NotificationBadge.defaultProps = {
  backgroundColor: 'notification',
  borderColor: null,
  color: 'white',
  size: 17,
  style: {},
  typographyPreset: 'tag--large',
  uiTheme: {},
};

export default withTheme(NotificationBadge);
