import { Cushion, Panel, Rectangle, ScrollBox } from '@pitchero/react-ui';
import { isEmpty, values } from 'lodash';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import CLUB_PROP_TYPES from '../../../../lib/prop-types/club';
import getNavItems from '../nav-items';
import Login from './login';
import NavItem from './nav-item';
import PromotedLinks from './promoted-links';
import SecondaryPanel from './secondary-panel';
import SocialLinks from './social-links';

const NavPanel = ({ activeSection, club, isOpen, onClose, navOffsetHeight, openLoginPanel }) => {
  const navItems = values(
    getNavItems({
      activeSection,
      club,
      limitTeams: false,
      groupMore: true,
    }),
  );

  const initialNavLevelTwo = { isOpen: false, props: {} };
  const initialNavLevelThree = { isOpen: false, props: {} };
  const [navPanelLevelTwo, setNavPanelLevelTwo] = useState(initialNavLevelTwo);
  const [navPanelLevelThree, setNavPanelLevelThree] = useState(initialNavLevelThree);
  const closeAllPanels = () => {
    setNavPanelLevelThree(initialNavLevelThree);
    setNavPanelLevelTwo(initialNavLevelTwo);
    onClose();
  };

  return (
    <Panel
      isOpen={isOpen}
      onClose={closeAllPanels}
      order={1}
      style={{
        width: '100%',
        maxWidth: '100%',
        right: isOpen ? 0 : '-100%',
        backgroundColor: 'black',
        top: `${navOffsetHeight}px`,
      }}
      closeIconOptions={{
        style: { display: 'none' },
      }}
      lightsOutOpacity={0}
    >
      <ScrollBox yOnly>
        <Login club={club} onClose={onClose} openPanel={openLoginPanel} />
        <Cushion bottom={`${navOffsetHeight}px`}>
          <Rectangle fill="#000">
            <Cushion horizontal="medium" top="xsmall" topcomponent="div">
              {navItems.map((item, i) => {
                const isLastItem = navItems.length === i + 1;
                const isActive = item.getIsActive(activeSection);

                return (
                  <NavItem
                    {...item}
                    key={item.id}
                    useSeparator={isLastItem}
                    isActive={isActive}
                    onClose={onClose}
                    onClick={
                      item.groupedOptions || item.options
                        ? () =>
                            setNavPanelLevelTwo({
                              ...initialNavLevelTwo,
                              isOpen: true,
                              props: { ...item },
                            })
                        : () => null
                    }
                  />
                );
              })}
            </Cushion>
          </Rectangle>
          {!isEmpty(club.promotedLinks) && <PromotedLinks links={club.promotedLinks} />}
          <Rectangle>
            <Cushion bottom="large">
              <SocialLinks
                facebookPage={club.facebookPage}
                instagramAccount={club.instagramAccount}
                twitterAccount={club.twitterAccount}
              />
            </Cushion>
          </Rectangle>
        </Cushion>
      </ScrollBox>
      <SecondaryPanel
        {...navPanelLevelTwo}
        navOffsetHeight={navOffsetHeight}
        order={2}
        onClose={closeAllPanels}
        onBackClick={() => setNavPanelLevelTwo(initialNavLevelTwo)}
        onClickWithOptions={(item) =>
          setNavPanelLevelThree({
            ...initialNavLevelThree,
            isOpen: true,
            props: { ...item },
          })
        }
      />
      <SecondaryPanel
        {...navPanelLevelThree}
        navOffsetHeight={navOffsetHeight}
        order={3}
        onClose={closeAllPanels}
        onBackClick={() => setNavPanelLevelThree(initialNavLevelThree)}
      />
    </Panel>
  );
};

NavPanel.propTypes = {
  activeSection: PropTypes.string,
  club: CLUB_PROP_TYPES.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  navOffsetHeight: PropTypes.number.isRequired,
  openLoginPanel: PropTypes.func.isRequired,
};
NavPanel.defaultProps = {
  activeSection: null,
};

export default NavPanel;
