import { combineReducers } from 'redux';
import { reject } from 'lodash';
import {
  TEAM_STATISTICS_PAGE_REQUEST,
  TEAM_STATISTICS_PAGE_RECEIVE,
  TEAM_STATISTICS_PAGE_FAILURE,
} from '../../action-types';

const currentlyLoading = (state = [], action) => {
  switch (action.type) {
    case TEAM_STATISTICS_PAGE_REQUEST: {
      const { teamId, seasonId } = action.payload;
      return [...state, [teamId, seasonId].join('-')];
    }
    case TEAM_STATISTICS_PAGE_FAILURE:
    case TEAM_STATISTICS_PAGE_RECEIVE: {
      const { teamId, seasonId } = action.payload;
      const ids = [...state];
      return reject(ids, (loadingId) => loadingId === [teamId, seasonId].join('-'));
    }
    default:
      return state;
  }
};

const hasLoaded = (state = [], action) => {
  switch (action.type) {
    case TEAM_STATISTICS_PAGE_RECEIVE: {
      const { teamId, seasonId } = action.payload;
      return [...state, [teamId, seasonId].join('-')];
    }
    default:
      return state;
  }
};

const loadingError = (state = [], action) => {
  switch (action.type) {
    case TEAM_STATISTICS_PAGE_REQUEST:
      return reject(state, (error) => error.teamId === action.payload.teamId);
    case TEAM_STATISTICS_PAGE_FAILURE: {
      return [...state, action.payload];
    }
    default:
      return state;
  }
};

const seasons = (state = {}, action) => {
  switch (action.type) {
    case TEAM_STATISTICS_PAGE_RECEIVE: {
      const { teamId, seasons: teamSeasons } = action.payload;
      return { ...state, [teamId]: teamSeasons };
    }
    default:
      return state;
  }
};

const statistics = (state = {}, action) => {
  switch (action.type) {
    case TEAM_STATISTICS_PAGE_RECEIVE: {
      const { teamId, seasonId, fixtureStatistics } = action.payload;
      const id = [teamId, seasonId].join('-');
      return { ...state, [id]: fixtureStatistics };
    }
    default:
      return state;
  }
};

const pointStatistics = (state = {}, action) => {
  switch (action.type) {
    case TEAM_STATISTICS_PAGE_RECEIVE: {
      const { teamId, seasonId, fixtureStatisticsBreakdown } = action.payload;
      const id = [teamId, seasonId].join('-');
      return { ...state, [id]: fixtureStatisticsBreakdown };
    }
    default:
      return state;
  }
};

const playerStatistics = (state = {}, action) => {
  switch (action.type) {
    case TEAM_STATISTICS_PAGE_RECEIVE: {
      const { teamId, seasonId, keyPlayerStatistics } = action.payload;
      const id = [teamId, seasonId].join('-');
      return { ...state, [id]: keyPlayerStatistics };
    }
    default:
      return state;
  }
};

const playerStatsTables = (state = {}, action) => {
  switch (action.type) {
    case TEAM_STATISTICS_PAGE_RECEIVE: {
      const { teamId, seasonId, playerStatisticsTables } = action.payload;
      const id = [teamId, seasonId].join('-');
      return { ...state, [id]: playerStatisticsTables };
    }
    default:
      return state;
  }
};

export default combineReducers({
  currentlyLoading,
  hasLoaded,
  loadingError,
  seasons,
  statistics,
  pointStatistics,
  playerStatistics,
  playerStatsTables,
});
