import { Button } from '@pitchero/react-ui';
import PropTypes from 'prop-types';
import React from 'react';
import TokenRedirect from '../../../token-redirect-link/route';
import { Trans } from '../../../../lib/i18n';

const Login = ({ isAssociated: isAssociatedToClub, isLoading, loggedIn }) => {
  if (!isLoading && loggedIn && isAssociatedToClub) {
    return null;
  }

  return (
    <div style={{ width: 120 }} className="footer-login">
      {!isLoading && !isAssociatedToClub && (
        <TokenRedirect route="join">
          <Button theme="primaryContrast" fullWidth>
            <Trans i18nKey="common:join">Join</Trans>
          </Button>
        </TokenRedirect>
      )}
    </div>
  );
};

Login.propTypes = {
  isAssociated: PropTypes.bool,
  isLoading: PropTypes.bool,
  loggedIn: PropTypes.bool,
};
Login.defaultProps = {
  loggedIn: false,
  isLoading: false,
  isAssociated: false,
};
export default Login;
