export default {
  rounded: 4,
  roundedTop: '4px 4px 0 0',
  roundedBottom: '0 0 4px 4px',
  roundedLeft: '4px 0 0 4px',
  roundedTopRight: '0 4px 0 0',
  roundedTopLeft: '4px 0 0 0',
  roundedTopRightAndBottomLeft: '0 4px 0 4px',
  roundedBottomLeft: '0 0 0 3px',
  speechBubble: '0px 12px 12px 12px',
  circle: '50%',
};
