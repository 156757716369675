export default {
  xxxxxlarge: 96,
  xxxxlarge: 84,
  xxxlarge: 72,
  xxlarge: 60,
  xlarge: 48,
  large: 36,
  medium: 24,
  small: 12,
  xsmall: 6,
  xxsmall: 3,
};
