import { ImageResizer } from '@pitchero/react-ui';

const usingUploadedLogo = (logo) =>
  logo &&
  (typeof logo === 'string' || logo instanceof String) &&
  !logo.startsWith('https://img-gen.pitchero.com/club-crest/');

const generateClubCrestUrl = (club, size) => {
  const { packageId, logo } = club;
  if (packageId !== 3 && usingUploadedLogo(logo)) {
    try {
      return ImageResizer.resizeUrl(logo, {
        w: size,
        h: size,
        t: 'square',
      });
    } catch (err) {
      // Fall back to the generated image below.
    }
  }

  const { colours: { primary = '#CCCCCC', secondary = '#999999' } = {} } = club;
  return `https://img-gen.pitchero.com/club-crest/${primary.substr(1)}/${secondary.substr(
    1,
  )}/${size}.png`;
};

export default generateClubCrestUrl;
