import { FlexContainer, Grid, Typography } from '@pitchero/react-ui';
import ArrowLeftIcon from '@pitchero/react-ui/dist/cjs/components/Icons/ArrowLeft';
import ArrowRightIcon from '@pitchero/react-ui/dist/cjs/components/Icons/ArrowRight';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import styled from 'styled-components';
import { Trans } from '../../lib/i18n';
import Sponsor from './sponsor';

const PageButton = styled.div`
  border: 2px solid black;
  border-radius: 100%;
  width: 100%;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SponsorsMobile = ({ sponsors, sponsorsPerPage }) => {
  const [page, setPage] = useState(1);
  const totalPages = Math.ceil(sponsors.length / sponsorsPerPage);
  const lastIndex = page * sponsorsPerPage;
  const firstIndex = lastIndex - sponsorsPerPage;

  return (
    <FlexContainer
      flexDirection="column"
      alignItems="center"
      style={{
        width: '100%',
        gap: '20px',
        backgroundColor: '#fff',
        padding: '20px',
        boxSizing: 'border-box',
      }}
    >
      <Typography preset="title" size={18}>
        <Trans i18nKey="common:club_sponsors">Club Sponsors</Trans>
      </Typography>
      <Grid columns="30px auto 30px" alignItems="center" columnGap="10px">
        <PageButton
          onClick={() => {
            if (page - 1 < 1) {
              setPage(totalPages); // Loop to end
            } else {
              setPage(page - 1);
            }
          }}
        >
          <ArrowLeftIcon size={20} />
        </PageButton>

        <Grid columns="1fr 1fr" columnGap="10px" alignItems="center" rowGap="10px">
          {sponsors.slice(firstIndex, lastIndex).map((sponsor) => (
            <div key={sponsor.id} style={{ display: 'flex', justifyContent: 'center' }}>
              <Sponsor {...sponsor} />
            </div>
          ))}
        </Grid>

        <PageButton
          onClick={() => {
            if (page + 1 > totalPages) {
              setPage(1); // Loop to start
            } else {
              setPage(page + 1);
            }
          }}
        >
          <ArrowRightIcon size={20} />
        </PageButton>
      </Grid>
    </FlexContainer>
  );
};

SponsorsMobile.propTypes = {
  sponsors: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      image: PropTypes.string,
      title: PropTypes.string,
      url: PropTypes.string,
    }),
  ).isRequired,
  sponsorsPerPage: PropTypes.number,
};

SponsorsMobile.defaultProps = {
  sponsorsPerPage: 4,
};

export default SponsorsMobile;
