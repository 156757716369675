import { get } from 'lodash';

export const isAttemptingLogin = (state) => get(state, 'auth.isAttemptingLogin', false);

export const isLoggedIn = (state) => get(state, 'auth.isLoggedIn', false);

export const loginStatus = (state) => get(state, 'auth.loginStatus', false);

export const hasBeenLoggedIn = (state) => get(state, 'auth.hasBeenLoggedIn', false);

export const loginFailed = (state) => get(state, 'auth.loginFailed', false);

export const hasCheckedLoginState = (state) => get(state, 'auth.hasCheckedLoginState', false);

export const isRetrievingUser = (state) => get(state, 'auth.isRetrievingUser', false);

export const loggedInUserId = (state) => get(state, 'auth.loggedInUser.user_id');

export const isLoginPanelOpen = (state) => get(state, 'auth.panelOpen', false);

export const isSubmittingPasswordReset = (state) =>
  get(state, 'auth.isSubmittingPasswordReset', false);

export const passwordResetHasFailed = (state) => get(state, 'auth.passwordResetFailed', false);

export const passwordResetStatus = (state) => get(state, 'auth.passwordResetStatus', 0);
