import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button, Cushion, Rectangle, Shadow, Typography } from '@pitchero/react-ui';
import { fetchThemeDraft, exitThemePreview } from '../../../store/clubs/actions';
import {
  loadingDraftTheme,
  hasLoadedDraftTheme,
  getDraftTheme,
  isIframePreview,
} from '../../../store/clubs/selectors';
import { Trans } from '../../../lib/i18n';
import clubUrl from '../../../lib/routing/club-url';
import CLUB_PROP_TYPES from '../../../lib/prop-types/club';

class DesignBuilder extends React.Component {
  componentDidMount() {
    this.loadIfRequired();
  }

  componentDidUpdate(prevProps) {
    const { themeDraftId } = this.props;
    const { themeDraftId: prevthemeDraftId } = prevProps;
    if (themeDraftId !== prevthemeDraftId) {
      this.loadIfRequired();
    }
  }

  loadIfRequired = () => {
    const { loadThemeDraft, club, themeDraftId, isLoading, hasLoaded } = this.props;
    if (!isLoading && !hasLoaded) {
      loadThemeDraft(club, themeDraftId);
    }
  };

  onExitHandler = () => {
    const { club, exitPreview } = this.props;
    exitPreview();
    window.location = clubUrl(club, 'homepage');
  };

  render() {
    const { draftTheme, isIframe } = this.props;
    const { author } = draftTheme;
    if (isIframe) {
      return null;
    }

    return (
      <div style={{ position: 'fixed', bottom: 0, left: 0, right: 0, zIndex: 999 }}>
        <Shadow shadow="0 1px 3px rgba(0,0,0,0.6)">
          <Rectangle fill="white">
            <Cushion horizontal="medium" vertical="small">
              <Typography preset="name">
                <Trans i18nKey="common:theme_draft_preview" values={{ author }}>
                  {`You are viewing a draft theme created by  ${author}`}
                </Trans>
              </Typography>
              <Button onClick={this.onExitHandler}>
                <Typography color="notification" preset="name">
                  Exit preview
                </Typography>
              </Button>
            </Cushion>
          </Rectangle>
        </Shadow>
      </div>
    );
  }
}

DesignBuilder.propTypes = {
  club: CLUB_PROP_TYPES.isRequired,
  draftTheme: PropTypes.shape({
    author: PropTypes.string,
  }).isRequired,
  exitPreview: PropTypes.func.isRequired,
  hasLoaded: PropTypes.bool.isRequired,
  isIframe: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  loadThemeDraft: PropTypes.func.isRequired,
  themeDraftId: PropTypes.string.isRequired,
};

const mapStateToProps = (state, { themeDraftId }) => ({
  draftTheme: getDraftTheme(state),
  hasLoaded: hasLoadedDraftTheme(state, themeDraftId),
  isLoading: loadingDraftTheme(state),
  isIframe: isIframePreview(state),
});

const dispatchToProps = {
  exitPreview: exitThemePreview,
  loadThemeDraft: fetchThemeDraft,
};

export default connect(mapStateToProps, dispatchToProps)(DesignBuilder);
