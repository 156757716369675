import { createStore, combineReducers, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunkMiddleware from 'redux-thunk';

import activityStream from './activity-stream/reducer';
import auth from './auth/reducer';
import bookings from './bookings/reducer';
import calendar from './calendar/reducer';
import clubEvents from './events/reducer';
import clubs from './clubs/reducer';
import comments from './comments/reducer';
import common from './common/reducer';
import contact from './contact/reducer';
import homepage from './homepage/reducer';
import information from './information/reducer';
import media from './media/reducer';
import membership from './membership/reducer';
import metaData from './meta-data/reducer';
import news from './news/reducer';
import pageInfo from './page-info/reducer';
import shop from './shop/reducer';
import sponsoredContent from './sponsored-content/reducer';
import teams from './teams/reducer';

const reducer = combineReducers({
  activityStream,
  auth,
  bookings,
  calendar,
  clubEvents,
  clubs,
  comments,
  common,
  contact,
  homepage,
  information,
  media,
  membership,
  metaData,
  news,
  pageInfo,
  shop,
  sponsoredContent,
  teams,
});

const initializeStore = (initialState = {}) =>
  createStore(reducer, initialState, composeWithDevTools(applyMiddleware(thunkMiddleware)));

export default initializeStore;
