import { Cushion, FlexContainer, Space } from '@pitchero/react-ui';
import FacebookIcon from '@pitchero/react-ui/dist/cjs/components/Icons/Facebook';
import InstagramIcon from '@pitchero/react-ui/dist/cjs/components/Icons/Instagram';
import TwitterIcon from '@pitchero/react-ui/dist/cjs/components/Icons/Twitter';
import PropTypes from 'prop-types';
import React from 'react';
import IconWithTheme from '../../../icon-with-theme';

const SocialLinks = ({ facebookPage, twitterAccount, instagramAccount }) => (
  <Cushion left="medium" top="medium" bottom="xlarge">
    <FlexContainer alignItems="center">
      {facebookPage && (
        <Space right="medium">
          <a href={facebookPage} title="facebook" aria-label="Club Facebook">
            <IconWithTheme>
              <FacebookIcon color="white" size={24} />
            </IconWithTheme>
          </a>
        </Space>
      )}
      {twitterAccount && (
        <Space right="medium">
          <a
            href={`https://twitter.com/${twitterAccount}`}
            title="twitter"
            aria-label="Club Twitter"
          >
            <IconWithTheme>
              <TwitterIcon color="white" size={20} viewBox="0 0 1200 1200" />
            </IconWithTheme>
          </a>
        </Space>
      )}
      {instagramAccount && (
        <Space right="medium">
          <a href={instagramAccount} title="instagram" aria-label="Club Instagram">
            <IconWithTheme>
              <InstagramIcon color="white" size={24} />
            </IconWithTheme>
          </a>
        </Space>
      )}
    </FlexContainer>
  </Cushion>
);

SocialLinks.defaultProps = {
  facebookPage: null,
  twitterAccount: null,
  instagramAccount: null,
};

SocialLinks.propTypes = {
  facebookPage: PropTypes.string,
  instagramAccount: PropTypes.string,
  twitterAccount: PropTypes.string,
};

export default SocialLinks;
