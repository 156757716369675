import { reduce } from 'lodash';

const sanitizeParam = (param) =>
  param.replace(/\s+/g, '_').replace(/\W/g, '').replace(/_/g, '-').toLowerCase();

const sanitizeParams = (paramsToSanitize) =>
  reduce(
    paramsToSanitize,
    (carry, val, key) => {
      const updated = { ...carry };
      if (key.startsWith('_')) {
        const sanitizedVal = sanitizeParam(val || key.substr(1));
        updated[key] = sanitizedVal || sanitizeParam(key.substr(1));
      } else {
        updated[key] = val;
      }

      return updated;
    },
    {},
  );

export default sanitizeParams;
