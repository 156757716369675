import React from 'react';
import PropTypes from 'prop-types';
import { withTheme } from '@pitchero/react-ui';

const IconWithTheme = ({ children, uiTheme, ...otherProps }) =>
  React.cloneElement(React.Children.only(children), {
    uiTheme,
    ...otherProps,
  });

IconWithTheme.propTypes = {
  children: PropTypes.node.isRequired,
  uiTheme: PropTypes.shape({}),
};
IconWithTheme.defaultProps = {
  uiTheme: {},
};
export default withTheme(IconWithTheme);
