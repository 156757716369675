import { Cushion, Rectangle, Shadow, Show } from '@pitchero/react-ui';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import Sponsors from '../sponsors/list';
import MobileNavigation from './mobile-navigation';
import TeamNavigation from './navigation';

const TeamFrame = ({ activeSection, children, isFreePackage, sponsors, team }) => {
  const unpaddedActiveSections = ['homepage', 'fixtures'];
  const padContent = (content) => (
    <Cushion
      component="div"
      horizontal="small"
      vertical="medium"
      responsive={[{ minWidth: 'palm', props: { horizontal: 'medium', bottom: 'large' } }]}
    >
      {content}
    </Cushion>
  );

  return (
    <>
      <Show maxWidth="fullHeader" component="div">
        <MobileNavigation activeSection={activeSection} team={team} />
      </Show>
      <Show minWidth="fullHeader" component="div">
        <TeamNavigation activeSection={activeSection} team={team} />
      </Show>
      <Shadow shadow={isFreePackage ? 'default' : null} className="contentWrapper" component="div">
        {unpaddedActiveSections.includes(activeSection) ? children : padContent(children)}
      </Shadow>
      {!isEmpty(sponsors) && (
        <Rectangle fill="white" borderBottom="thinLightGrey">
          <Sponsors sponsors={sponsors} titleTranslationKey="common:team_sponsors" />
        </Rectangle>
      )}
    </>
  );
};

TeamFrame.defaultProps = {
  sponsors: [],
};

TeamFrame.propTypes = {
  activeSection: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]).isRequired,
  isFreePackage: PropTypes.bool.isRequired,
  team: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
  sponsors: PropTypes.arrayOf(PropTypes.shape()),
};

export default TeamFrame;
