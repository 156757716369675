import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Button, Cushion, Rectangle, Typography, FlexContainer, Image } from '@pitchero/react-ui';

import CrossIcon from '@pitchero/react-ui/dist/cjs/components/Icons/Cross';

import Cookies from 'universal-cookie';

import { isMobile } from 'react-device-detect';
import IconWithTheme from '../icon-with-theme';

const cookies = new Cookies();

const closeBanner = () => {
  const daysInTheFutureDate = new Date();
  daysInTheFutureDate.setDate(daysInTheFutureDate.setDate() + 30);
  cookies.set('APP_AD', true, { expires: daysInTheFutureDate, sameSite: true });
};

const AppAdBanner = ({ club, clubAppLogoSrc, onClose }) => {
  const [closed, setClosed] = useState(null);

  if (!isMobile) {
    return null;
  }

  const hasCookie = cookies.get('APP_AD');

  if (hasCookie || closed) {
    return null;
  }

  const titleCombined = club.siteTitle ? club.siteTitle : 'Club App';

  return (
    <Rectangle component="div" fill="white">
      <Cushion top="small" bottom="small" left="xsmall" component="div">
        <FlexContainer alignItems="flex-start">
          <Rectangle radius="rounded" component="div">
            <Image src={clubAppLogoSrc} alt={titleCombined} width={65} height={65} />
          </Rectangle>
          <Cushion style={{ flexGrow: '5' }} left="small">
            <Typography
              component="div"
              style={{ lineHeight: '25px' }}
              size={19}
              color={club.colours.primary}
            >
              {titleCombined}
            </Typography>
            <Typography style={{ lineHeight: '12px' }} size={13}>
              For the latest news, fixtures and results.
            </Typography>
            <FlexContainer alignItems="center">
              <a target="_blank" href="https://pitchero.page.link/join" rel="noreferrer">
                <Button style={{ padding: '5px 11px' }} theme="primary">
                  <Typography weight="medium" size={12}>
                    Download Club App
                  </Typography>
                </Button>
              </a>
              <Cushion left="small">
                <Typography size={12}>By Pitchero</Typography>
              </Cushion>
            </FlexContainer>
          </Cushion>
          <Cushion style={{ alignSelf: 'flex-start' }} right="small">
            <IconWithTheme color="grey">
              <CrossIcon
                onClick={() => {
                  setClosed(true);
                  closeBanner();
                  onClose();
                }}
              />
            </IconWithTheme>
          </Cushion>
        </FlexContainer>
      </Cushion>
    </Rectangle>
  );
};

AppAdBanner.defaultProps = {
  clubAppLogoSrc: 'https://images.pitchero.com/app-icons/club-app.png',
};

AppAdBanner.propTypes = {
  clubAppLogoSrc: PropTypes.string,
  club: PropTypes.shape({
    siteTitle: PropTypes.string,
    colours: PropTypes.shape({
      primary: PropTypes.string.isRequired,
    }),
  }).isRequired,
  onClose: PropTypes.func.isRequired,
};

export default AppAdBanner;
