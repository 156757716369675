import { Cushion, Rectangle, Shadow } from '@pitchero/react-ui';
import PropTypes from 'prop-types';
import React from 'react';

const Container = ({ children, fullWidth, leftOffset, topAdjustment }) => {
  const fullWidthSize = '66vw';
  const leftPosition = leftOffset * -1;

  return (
    <Cushion
      right="medium"
      component="div"
      style={{
        position: 'absolute',
        top: `calc(100% + ${topAdjustment}px)`,
        left: fullWidth ? leftPosition : null,
        zIndex: 1,
        width: fullWidth ? fullWidthSize : 'auto',
        boxSizing: 'border-box',
      }}
    >
      <Shadow>
        <Rectangle
          fill="white"
          style={{
            borderRadius: '5px',
            borderTopLeftRadius: fullWidth ? '5px' : '0px',
          }}
        >
          <Cushion horizontal="medium" vertical="large" component="div">
            {children}
          </Cushion>
        </Rectangle>
      </Shadow>
    </Cushion>
  );
};

Container.propTypes = {
  children: PropTypes.node.isRequired,
  fullWidth: PropTypes.bool,
  leftOffset: PropTypes.number.isRequired,
  topAdjustment: PropTypes.number.isRequired,
};
Container.defaultProps = {
  fullWidth: false,
};

export default Container;
