import { Cushion, Grid, Panel, Rectangle, ScrollBox, Typography } from '@pitchero/react-ui';
import BackArrowIcon from '@pitchero/react-ui/dist/cjs/components/Icons/BackArrow';
import PropTypes from 'prop-types';
import React from 'react';
import { Trans } from '../../../../lib/i18n';
import IconWithTheme from '../../../icon-with-theme';
import NavItem from './nav-item';

const SecondaryPanel = ({
  isOpen,
  onClose,
  onBackClick,
  order,
  props: { name, groupedOptions, options, transKey },
  onClickWithOptions,
  navOffsetHeight,
  ...otherProps
}) => {
  const items = groupedOptions || options;
  return (
    <Panel
      isOpen={isOpen}
      onClose={onClose}
      order={order}
      style={{
        width: '100%',
        maxWidth: '100%',
        right: isOpen ? 0 : '-100%',
        backgroundColor: order === 3 ? '#2C2C2C' : '#000',
        top: `${navOffsetHeight}px`,
      }}
      closeIconOptions={{
        style: { display: 'none' },
      }}
      lightsOutOpacity={0}
      {...otherProps}
    >
      <Grid rows="auto minmax(0, 1fr)" style={{ maxHeight: '100%' }}>
        <Rectangle fill="#000">
          <Cushion vertical="large" left="medium" right="xxxlarge" component="div">
            <Typography
              preset="name"
              color="white"
              fill="black"
              onClick={onBackClick}
              onKeyPress={onBackClick}
              aria-label="back"
              role="button"
              tabIndex={-1}
              size="large"
              style={{ cursor: 'pointer' }}
            >
              <IconWithTheme>
                <BackArrowIcon color="white" topOffset={2} />
              </IconWithTheme>
              <Cushion left="medium" component="span">
                {transKey ? <Trans i18nKey={transKey} /> : name}
              </Cushion>
            </Typography>
          </Cushion>
        </Rectangle>
        <ScrollBox yOnly component="div">
          <Cushion horizontal="medium" bottom={`${navOffsetHeight}px`} component="div">
            {items &&
              items.map((item, i) => {
                const isLastItem = items.length === i + 1;
                return (
                  <NavItem
                    {...item}
                    key={item.id}
                    noSeperator={isLastItem}
                    onClose={onClose}
                    onClick={
                      item.groupedOptions || item.options
                        ? () => onClickWithOptions({ ...item })
                        : () => null
                    }
                  />
                );
              })}
          </Cushion>
        </ScrollBox>
      </Grid>
    </Panel>
  );
};

SecondaryPanel.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onBackClick: PropTypes.func,
  onClickWithOptions: PropTypes.func,
  order: PropTypes.number.isRequired,
  props: PropTypes.shape(),
  navOffsetHeight: PropTypes.number.isRequired,
};
SecondaryPanel.defaultProps = {
  onClickWithOptions: () => null,
  onBackClick: () => null,
  props: {},
};
export default SecondaryPanel;
