import { combineReducers } from 'redux';
import homepage from './homepage/reducer';
import matchCentre from './match-centre/reducer';
import matchReports from './match-reports/reducer';
import teamSquad from './squad/reducer';
import leagueTable from './league-table/reducer';
import fixtures from './fixtures/reducer';
import statistics from './stats/reducer';

export default combineReducers({
  homepage,
  matchCentre,
  matchReports,
  teamSquad,
  leagueTable,
  fixtures,
  statistics,
});
