export default {
  presets: {
    title: {
      size: 18,
      topOffset: 2,
    },
    'subtitle--large': {
      size: 18,
      topOffset: 2,
    },
    'subtitle--large--24': {
      size: 24,
      topOffset: 5,
    },
    'subtitle--small': {
      size: 14,
      topOffset: 1,
    },
    tab: {
      size: 16,
      topOffset: 2,
    },
    'tag--large': {
      size: 13,
      topOffset: 1,
    },
    'tag--small': {
      size: 10,
      topOffset: 1,
    },
    description: {
      size: 11,
      topOffset: 1,
    },
    button: {
      size: 14,
      topOffset: 1,
    },
    'body--large': {
      size: 16,
      topOffset: 2,
    },
    'body--small': {
      size: 14,
      topOffset: 1,
    },
    name: {
      size: 12,
      topOffset: 1,
    },
    breadcrumb: {
      size: 14,
      topOffset: 2,
    },
  },
};
