import { combineReducers } from 'redux';
import { reject, uniq, uniqBy, orderBy } from 'lodash';
import {
  TEAM_HOMEPAGE_REQUEST,
  TEAM_HOMEPAGE_RECEIVE,
  TEAM_HOMEPAGE_FAILURE,
} from '../../action-types';

const currentlyLoadingTeams = (state = [], action) => {
  switch (action.type) {
    case TEAM_HOMEPAGE_REQUEST:
      return [...state, action.payload.teamId];
    case TEAM_HOMEPAGE_FAILURE:
    case TEAM_HOMEPAGE_RECEIVE: {
      const { teamId } = action.payload;
      return reject([...state], (loadingId) => loadingId === teamId);
    }

    default:
      return state;
  }
};

const loadedTeams = (state = [], action) => {
  switch (action.type) {
    case TEAM_HOMEPAGE_RECEIVE:
      return uniq([...state, action.payload.teamId]);
    default:
      return state;
  }
};

const loadingErrors = (state = [], action) => {
  switch (action.type) {
    case TEAM_HOMEPAGE_REQUEST:
      return reject(state, (error) => error.teamId === action.payload.teamId);
    case TEAM_HOMEPAGE_FAILURE: {
      return [...state, action.payload];
    }
    default:
      return state;
  }
};

const pageData = (state = [], action) => {
  switch (action.type) {
    case TEAM_HOMEPAGE_RECEIVE: {
      const { data, teamId } = action.payload;
      const matches = orderBy(data.matches, 'dateTime');
      return uniqBy([...state, { ...data, matches, teamId }], 'teamId');
    }
    default:
      return state;
  }
};

export default combineReducers({
  currentlyLoadingTeams,
  loadedTeams,
  loadingErrors,
  pageData,
});
