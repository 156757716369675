/**
 * AUTH
 */
export const LOGIN_REQUEST = 'AUTH/LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'AUTH/LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'AUTH/LOGIN_FAILURE';

export const PASSWORD_RESET_REQUEST = 'AUTH/PASSWORD_RESET_REQUEST';
export const PASSWORD_RESET_SUCCESS = 'AUTH/PASSWORD_RESET_SUCCESS';
export const PASSWORD_RESET_FAILURE = 'AUTH/PASSWORD_RESET_FAILURE';

export const LOGIN_PANEL_CLOSE = 'AUTH/LOGIN_PANEL_CLOSE';
export const LOGIN_PANEL_OPEN = 'AUTH/LOGIN_PANEL_OPEN';

export const LOGOUT = 'AUTH/LOGOUT';

export const PAGE_DATA_LOAD = 'PAGE_DATA/LOAD';

export const NETWORK_PANEL_REQUEST = 'NETWORK_PANEL/REQUEST';
export const NETWORK_PANEL_RECEIVE = 'NETWORK_PANEL/RECEIVE';
export const NETWORK_PANEL_FAILURE = 'NETWORK_PANEL/FAILURE';
export const NETWORK_PANEL_REQUEST_SUCCESS = 'NETWORK_PANEL/REQUEST_SUCCESS';
export const NETWORK_PANEL_OPEN = 'NETWORK_PANEL/OPEN';
export const NETWORK_PANEL_CLOSE = 'NETWORK_PANEL/CLOSE';

export const MEMBERSHIP_APPLY_CLUB_MEMBER_REQUEST = 'MEMBERSHIP/APPLY_CLUB_MEMBER/REQUEST';
export const MEMBERSHIP_APPLY_CLUB_MEMBER_RECEIVE = 'MEMBERSHIP/APPLY_CLUB_MEMBER/RECEIVE';
export const MEMBERSHIP_APPLY_CLUB_MEMBER_FAILURE = 'MEMBERSHIP/APPLY_CLUB_MEMBER/FAILURE';

export const USER_REQUEST = 'AUTH/USER_REQUEST';
export const USER_RECEIVE = 'AUTH/USER_RECEIVE';
export const USER_FAILURE = 'AUTH/USER_FAILURE';

export const UNAUTHORISED_RESPONSE = 'UNAUTHORISED_RESPONSE';

/**
 * ACTIVITY_STREAM
 */
export const ACTIVITY_STREAM_REQUEST = 'ACTIVITY_STREAM/REQUEST';
export const ACTIVITY_STREAM_RECEIVE = 'ACTIVITY_STREAM/RECEIVE';
export const ACTIVITY_STREAM_FAILURE = 'ACTIVITY_STREAM/FAILURE';

export const RELATED_CONTENT_REQUEST = 'RELATED_CONTENT/REQUEST';
export const RELATED_CONTENT_RECEIVE = 'RELATED_CONTENT/RECEIVE';
export const RELATED_CONTENT_FAILURE = 'RELATED_CONTENT/FAILURE';

/**
 * ALBUMS
 */
export const ALBUM_LIST_REQUEST = 'ALBUM_LIST/REQUEST';
export const ALBUM_LIST_RECEIVE = 'ALBUM_LIST/RECEIVE';
export const ALBUM_LIST_FAILURE = 'ALBUM_LIST/FAILURE';
export const CLUB_ALBUM_REQUEST = 'CLUB_ALBUM/REQUEST';
export const CLUB_ALBUM_RECEIVE = 'CLUB_ALBUM/RECEIVE';
export const CLUB_ALBUM_FAILURE = 'CLUB_ALBUM/FAILURE';

/**
 * AVAILABILITY
 */
export const SET_AVAILABILITY_REQUEST = 'AVAILABILITY/SET_REQUEST';
export const SET_AVAILABILITY_SUCCESS = 'AVAILABILITY/SET_SUCCESS';
export const SET_AVAILABILITY_FAILURE = 'AVAILABILITY/SET_FAILURE';

/**
 * CALENDAR
 */
export const CALENDAR_REQUEST = 'CALENDAR/REQUEST';
export const CALENDAR_RECEIVE = 'CALENDAR/RECEIVE';
export const CALENDAR_FAILURE = 'CALENDAR/FAILURE';

/**
 * CLUB EVENTS
 */
export const CLUB_EVENT_REQUEST = 'CLUB_EVENT/REQUEST';
export const CLUB_EVENT_RECEIVE = 'CLUB_EVENT/RECEIVE';
export const CLUB_EVENT_FAILURE = 'CLUB_EVENT/FAILURE';

export const CLUB_EVENT_ATTENDANCE_REQUEST = 'CLUB_EVENT/ATTENDANCE_REQUEST';
export const CLUB_EVENT_ATTENDANCE_RECEIVE = 'CLUB_EVENT/ATTENDANCE_RECEIVE';
export const CLUB_EVENT_ATTENDANCE_FAILURE = 'CLUB_EVENT/ATTENDANCE_FAILURE';

export const CLUB_EVENT_SET_ATTENDANCE_REQUEST = 'CLUB_EVENT/SET_ATTENDANCE_REQUEST';
export const CLUB_EVENT_SET_ATTENDANCE_SUCCESS = 'CLUB_EVENT/SET_ATTENDANCE_SUCCESS';
export const CLUB_EVENT_SET_ATTENDANCE_FAILURE = 'CLUB_EVENT/SET_ATTENDANCE_FAILURE';

/**
 * TRAINING
 */
export const TRAINING_SESSION_REQUEST = 'TRAINING/SESSION_REQUEST';
export const TRAINING_SESSION_RECEIVE = 'TRAINING/SESSION_RECEIVE';
export const TRAINING_SESSION_FAILURE = 'TRAINING/SESSION_FAILURE';
export const TRAINING_AVAILABILITY_REQUEST = 'TRAINING/AVAILABILITY_REQUEST';
export const TRAINING_AVAILABILITY_RECEIVE = 'TRAINING/AVAILABILITY_RECEIVE';
export const TRAINING_AVAILABILITY_FAILURE = 'TRAINING/AVAILABILITY_FAILURE';

/**
 * CLUB INFORMATION
 */
export const CLUB_INFORMATION_REQUEST = 'CLUB_INFORMATION/REQUEST';
export const CLUB_INFORMATION_RECEIVE = 'CLUB_INFORMATION/RECEIVE';
export const CLUB_INFORMATION_FAILURE = 'CLUB_INFORMATION/FAILURE';

/**
 * COMMENTS
 */
export const COMMENTS_REQUEST = 'COMMENTS/REQUEST';
export const COMMENTS_RECEIVE = 'COMMENTS/RECEIVE';
export const COMMENTS_FAILURE = 'COMMENTS/FAILURE';

export const COMMENTS_PERMISSIONS_REQUEST = 'COMMENTS_PERMISSIONS/REQUEST';
export const COMMENTS_PERMISSIONS_RECEIVE = 'COMMENTS_PERMISSIONS/RECEIVE';
export const COMMENTS_PERMISSIONS_FAILURE = 'COMMENTS_PERMISSIONS/FAILURE';

export const ADD_COMMENT_REQUEST = 'ADD_COMMENT/REQUEST';
export const ADD_COMMENT_SUCCESS = 'ADD_COMMENT/SUCCESS';
export const ADD_COMMENT_FAILURE = 'ADD_COMMENT/FAILURE';

export const DELETE_COMMENT_REQUEST = 'DELETE_COMMENT/REQUEST';
export const DELETE_COMMENT_SUCCESS = 'DELETE_COMMENT/SUCCESS';
export const DELETE_COMMENT_FAILURE = 'DELETE_COMMENT/FAILURE';

export const RESTORE_COMMENT_REQUEST = 'RESTORE_COMMENT/REQUEST';
export const RESTORE_COMMENT_SUCCESS = 'RESTORE_COMMENT/SUCCESS';
export const RESTORE_COMMENT_FAILURE = 'RESTORE_COMMENT/FAILURE';

/**
 * CONTACTS
 */
export const CONTACT_REQUEST = 'CONTACT_REQUEST';
export const CONTACT_RECEIVE = 'CONTACT_RECEIVE';
export const CONTACT_FAILURE = 'CONTACT_FAILURE';
export const CONTACT_OFFICIAL_REQUEST = 'CONTACT_OFFICIAL_REQUEST';
export const CONTACT_OFFICIAL_RECEIVE = 'CONTACT_OFFICIAL_RECEIVE';
export const CONTACT_OFFICIAL_FAILURE = 'CONTACT_OFFICIAL_FAILURE';

/**
 * HOMEPAGE
 */
export const HOMEPAGE_REQUEST = 'HOMEPAGE/REQUEST';
export const HOMEPAGE_RECEIVE = 'HOMEPAGE/RECEIVE';
export const HOMEPAGE_FAILURE = 'HOMEPAGE/FAILURE';

/**
 * NEWS
 */
export const NEWS_PAGE_REQUEST = 'NEWS_PAGE_DATA/REQUEST';
export const NEWS_PAGE_FAILURE = 'NEWS_PAGE_DATA/FAILURE';
export const NEWS_ARTICLE_REQUEST = 'NEWS_ARTICLE/REQUEST';
export const NEWS_ARTICLE_RECEIVE = 'NEWS_ARTICLE/RECEIVE';
export const NEWS_ARTICLE_FAILURE = 'NEWS_ARTICLE/FAILURE';

/**
 * SPONSORED CONTENT
 */

export const SPONSORED_ARTICLE_REQUEST = 'SPONSORED_ARTICLE_REQUEST';
export const SPONSORED_ARTICLE_RECEIVE = 'SPONSORED_ARTICLE_RECEIVE';
export const SPONSORED_ARTICLE_FAILURE = 'SPONSORED_ARTICLE_FAILURE';

/**
 * SHOP
 */

export const SHOP_ASSIGNMENTS_REQUEST = 'SHOP/ASSIGNMENTS/REQUEST';
export const SHOP_ASSIGNMENTS_RECEIVE = 'SHOP/ASSIGNMENTS/RECEIVE';
export const SHOP_ASSIGNMENTS_FAILURE = 'SHOP/ASSIGNMENTS/FAILURE';
export const SHOP_LANDING_PAGE_REQUEST = 'SHOP/LANDING_PAGE/REQUEST';
export const SHOP_LANDING_PAGE_RECEIVE = 'SHOP/LANDING_PAGE/RECEIVE';
export const SHOP_LANDING_PAGE_FAILURE = 'SHOP/LANDING_PAGE/FAILURE';
export const SHOP_CATEGORY_PAGE_REQUEST = 'SHOP/CATEGORY_PAGE/REQUEST';
export const SHOP_CATEGORY_PAGE_RECEIVE = 'SHOP/CATEGORY_PAGE/RECEIVE';
export const SHOP_CATEGORY_PAGE_FAILURE = 'SHOP/CATEGORY_PAGE/FAILURE';
export const SHOP_PRODUCT_PAGE_REQUEST = 'SHOP/PRODUCT_PAGE/REQUEST';
export const SHOP_PRODUCT_PAGE_RECEIVE = 'SHOP/PRODUCT_PAGE/RECEIVE';
export const SHOP_PRODUCT_PAGE_FAILURE = 'SHOP/PRODUCT_PAGE/FAILURE';
export const SHOP_BANNER_CLOSED_TOGGLED = 'SHOP/BANNER/TOGGLED';

/**
 * TEAMS
 */
export const TEAM_HOMEPAGE_REQUEST = 'TEAMS/HOMEPAGE_DATA/REQUEST';
export const TEAM_HOMEPAGE_RECEIVE = 'TEAMS/HOMEPAGE_DATA/RECEIVE';
export const TEAM_HOMEPAGE_FAILURE = 'TEAMS/HOMEPAGE_DATA/FAILURE';
export const MATCH_CENTRE_PAGE_REQUEST = 'TEAMS/MATCH_CENTRE_PAGE_DATA/REQUEST';
export const MATCH_CENTRE_PAGE_RECEIVE = 'TEAMS/MATCH_CENTRE_PAGE_DATA/RECEIVE';
export const MATCH_CENTRE_PAGE_FAILURE = 'TEAMS/MATCH_CENTRE_PAGE_DATA/FAILURE';
export const MATCH_CENTRE_UPDATES_RECEIVE = 'TEAMS/MATCH_CENTRE_UPDATES/RECEIVE';
export const MATCH_CENTRE_UPDATES_FAILURE = 'TEAMS/MATCH_CENTRE_UPDATES/FAILURE';
export const MATCH_CENTRE_UPDATES_VIEW = 'TEAMS/MATCH_CENTRE_UPDATES/VIEW';
export const MATCH_REPORTS_PAGE_REQUEST = 'TEAMS/MATCH_REPORTS_PAGE_DATA/REQUEST';
export const MATCH_REPORTS_PAGE_RECEIVE = 'TEAMS/MATCH_REPORTS_PAGE_DATA/RECEIVE';
export const MATCH_REPORTS_PAGE_FAILURE = 'TEAMS/MATCH_REPORTS_PAGE_DATA/FAILURE';
export const TEAM_SQUAD_PAGE_REQUEST = 'TEAMS/SQUAD_PAGE_DATA/REQUEST';
export const TEAM_SQUAD_PAGE_RECEIVE = 'TEAMS/SQUAD_PAGE_DATA/RECEIVE';
export const TEAM_SQUAD_PAGE_FAILURE = 'TEAMS/SQUAD_PAGE_DATA/FAILURE';
export const TEAM_PROFILE_PAGE_REQUEST = 'TEAMS/PROFILE_PAGE_DATA/REQUEST';
export const TEAM_PROFILE_PAGE_RECEIVE = 'TEAMS/PROFILE_PAGE_DATA/RECEIVE';
export const TEAM_PROFILE_PAGE_FAILURE = 'TEAMS/PROFILE_PAGE_DATA/FAILURE';
export const TEAM_LEAGUE_TABLE_PAGE_REQUEST = 'TEAMS/LEAGUE_TABLE_PAGE_DATA/REQUEST';
export const TEAM_LEAGUE_TABLE_PAGE_RECEIVE = 'TEAMS/LEAGUE_TABLE_PAGE_DATA/RECEIVE';
export const TEAM_LEAGUE_TABLE_PAGE_FAILURE = 'TEAMS/LEAGUE_TABLE_PAGE_DATA/FAILURE';
export const TEAM_FIXTURES_PAGE_REQUEST = 'TEAMS/FIXTURES_PAGE_DATA/REQUEST';
export const TEAM_FIXTURES_PAGE_RECEIVE = 'TEAMS/FIXTURES_PAGE_DATA/RECEIVE';
export const TEAM_FIXTURES_PAGE_FAILURE = 'TEAMS/FIXTURES_PAGE_DATA/FAILURE';
export const TEAM_STATISTICS_PAGE_REQUEST = 'TEAMS/STATISTICS_PAGE_DATA/REQUEST';
export const TEAM_STATISTICS_PAGE_RECEIVE = 'TEAMS/STATISTICS_PAGE_DATA/RECEIVE';
export const TEAM_STATISTICS_PAGE_FAILURE = 'TEAMS/STATISTICS_PAGE_DATA/FAILURE';

export const SELECT_SEASON = 'SEASON/SELECT';

/**
 * DRAFT THEMES
 */
export const DRAFT_THEME_REQUEST = 'DRAFT_THEME/REQUEST';
export const DRAFT_THEME_RECEIVE = 'DRAFT_THEME/RECEIVE';
export const DRAFT_THEME_FAILURE = 'DRAFT_THEME/FAILURE';
export const DRAFT_THEME_CLEAR = 'DRAFT_THEME/CLEAR';

/**
 * VIDEOS
 */
export const VIDEO_LIST_REQUEST = 'VIDEO_LIST/REQUEST';
export const VIDEO_LIST_RECEIVE = 'VIDEO_LIST/RECEIVE';
export const VIDEO_LIST_FAILURE = 'VIDEO_LIST/FAILURE';
export const CLUB_VIDEO_REQUEST = 'CLUB_VIDEO/REQUEST';
export const CLUB_VIDEO_RECEIVE = 'CLUB_VIDEO/RECEIVE';
export const CLUB_VIDEO_FAILURE = 'CLUB_VIDEO/FAILURE';
export const VIDEO_WATCHED = 'VIDEO_WATCHED';

/**
 * AGE GATE
 */

export const CONFIRMED_OVER_EIGHTEEN = 'CONFIRMED_OVER_EIGHTEEN';

/**
 * PAGE INFO
 */
export const ADVERTS_SETUP_UPDATE = 'ADVERTS_SETUP_UPDATE';

/**
 * BOOKINGS
 */
export const BOOKINGS_WIDGET_RECEIVE = 'BOOKINGS/WIDGET/RECEIVE';
