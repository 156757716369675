export default {
  primary: '#105b7d',
  primaryContrast: '#FFF',
  secondary: '#00D296',
  secondaryContrast: '#FFF',
  gold: '#FFD058',
  positive: '#00D296',
  neutral: '#FFCC63',
  negative: '#F15E64',
  availability_available: '#00D296',
  availability_not_sure: '#FFCC63',
  availability_unavailable: '#F15E64',
  availability_not_set: '#00a0d1',
  black: '#333',
  dovegrey: '#666',
  dustygrey: '#999',
  silver: '#c8c8c8',
  alto: '#d6d6d6',
  mercury: '#e7e7e7',
  seashellgrey: '#F1F1F1',
  alabaster: '#F7F7F7',
  white: '#FFF',
  outcomeBgL: '#F15E64',
  outcomeBgW: '#00D296',
  outcomeBgD: '#bebcbc',
  outcomeBgP: '#bebcbc',
  outcomeBgA: '#bebcbc',
  outcomeBgC: '#bebcbc',
  outcomeBgT: '#bebcbc',
  facebook: '#4267b2',
  twitter: '#38A1F3',
  notification: '#00a0d1',
};
