import { get, find, isEmpty } from 'lodash';

export const getAssociatedClubs = (state) => get(state, 'membership.networkPanel.clubs', []);

export const getAssociatedLeagueWebsites = (state) =>
  get(state, 'membership.networkPanel.leagueWebsites', []);

export const getBadgeTotals = (state) => get(state, 'membership.networkPanel.badgeTotals', {});

export const getTotalNotificationCount = (state) =>
  get(state, 'membership.networkPanel.badgeTotals.total', 0);

export const getCompetitionsAdministered = (state) =>
  get(state, 'membership.networkPanel.competitions', []);

export const getAssociatedClub = (state, id) => find(getAssociatedClubs(state), { id });

export const isSubmittingApplication = (state) =>
  get(state, 'membership.submittingApplication', false);

export const getPrimaryMemberId = (state, clubId) => {
  const club = getAssociatedClub(state, clubId);
  return club ? club.memberId : null;
};

export const isAssociated = (state, id) => {
  const club = getAssociatedClub(state, id);
  return !!club;
};

export const isAuthorised = (state, id) => {
  const club = getAssociatedClub(state, id);
  return get(club, 'isAuthorised', false);
};

export const hasAppliedForMembership = (state, id) => {
  const club = getAssociatedClub(state, id);
  return get(club, 'hasPendingMembershipApplication', false);
};

export const isLoadingNetworkPanel = (state) => get(state, 'membership.networkPanelLoading', false);

export const hasLoadedNetworkPanel = (state) => !isEmpty(get(state, 'membership.networkPanel'));

export const getLoggedInUser = (state) => get(state, 'membership.networkPanel.user', null);

export const isNetworkPanelOpen = (state) => get(state, 'membership.networkPanelOpen');
