import { combineReducers } from 'redux';
import {
  CONTACT_RECEIVE,
  CONTACT_REQUEST,
  CONTACT_FAILURE,
  CONTACT_OFFICIAL_RECEIVE,
  CONTACT_OFFICIAL_REQUEST,
  CONTACT_OFFICIAL_FAILURE,
} from '../action-types';

const currentlyLoadingClubs = (state = [], action) => {
  switch (action.type) {
    case CONTACT_REQUEST:
      return [...state, action.payload.clubId];
    case CONTACT_RECEIVE:
    case CONTACT_FAILURE:
      return [...state].filter((loadingClubId) => loadingClubId !== action.payload.clubId);
    default:
      return state;
  }
};

const loadedClubs = (state = [], action) => {
  switch (action.type) {
    case CONTACT_RECEIVE:
      return [...state, action.payload.clubId];
    default:
      return state;
  }
};

const locations = (state = {}, action) => {
  switch (action.type) {
    case CONTACT_RECEIVE: {
      const { clubId, locations: loadedLocations } = action.payload;
      return { ...state, [clubId]: loadedLocations };
    }
    default:
      return state;
  }
};

const officialGroups = (state = {}, action) => {
  switch (action.type) {
    case CONTACT_RECEIVE: {
      const { clubId, officialGroups: loadedGroups } = action.payload;
      return { ...state, [clubId]: loadedGroups };
    }
    default:
      return state;
  }
};

const otherOfficials = (state = {}, action) => {
  switch (action.type) {
    case CONTACT_RECEIVE: {
      const { clubId, otherOfficials: loadedGroups } = action.payload;
      return { ...state, [clubId]: loadedGroups };
    }
    default:
      return state;
  }
};

const loadingErrors = (state = {}, action) => {
  switch (action.type) {
    case CONTACT_FAILURE: {
      const { clubId, error } = action.payload;
      return { ...state, [clubId]: error };
    }
    default:
      return state;
  }
};

const contactingOfficial = (state = false, action) => {
  switch (action.type) {
    case CONTACT_OFFICIAL_REQUEST: {
      return true;
    }
    case CONTACT_OFFICIAL_FAILURE:
    case CONTACT_OFFICIAL_RECEIVE: {
      return false;
    }
    default:
      return state;
  }
};

export default combineReducers({
  currentlyLoadingClubs,
  loadedClubs,
  locations,
  officialGroups,
  otherOfficials,
  loadingErrors,
  contactingOfficial,
});
