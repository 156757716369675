import { combineReducers } from 'redux';
import { get, find, reject, uniqBy } from 'lodash';
import {
  CLUB_EVENT_REQUEST,
  CLUB_EVENT_RECEIVE,
  CLUB_EVENT_FAILURE,
  CLUB_EVENT_ATTENDANCE_REQUEST,
  CLUB_EVENT_ATTENDANCE_RECEIVE,
  CLUB_EVENT_ATTENDANCE_FAILURE,
  CLUB_EVENT_SET_ATTENDANCE_REQUEST,
  CLUB_EVENT_SET_ATTENDANCE_FAILURE,
} from '../action-types';

const loadingEvents = (state = [], action) => {
  switch (action.type) {
    case CLUB_EVENT_REQUEST: {
      return [...state, action.payload.eventId];
    }
    case CLUB_EVENT_RECEIVE:
    case CLUB_EVENT_FAILURE: {
      const eventIds = [...state];
      return reject(eventIds, (loadingId) => loadingId === action.payload.eventId);
    }
    default:
      return state;
  }
};

const hasAttemptedLoad = (state = [], action) => {
  switch (action.type) {
    case CLUB_EVENT_RECEIVE:
    case CLUB_EVENT_FAILURE: {
      return [...state, action.payload.eventId];
    }
    default:
      return state;
  }
};

const events = (state = [], action) => {
  switch (action.type) {
    case CLUB_EVENT_RECEIVE: {
      return [...state, action.payload.event];
    }
    default:
      return state;
  }
};

const addEventAttendee = (state, eventId, member) => {
  const attendees = get(state, eventId, []);

  return {
    ...state,
    [eventId]: uniqBy([...attendees, member], 'memberId'),
  };
};

const removeEventAttendee = (state, eventId, member) => {
  const attendees = get(state, eventId, []);

  return {
    ...state,
    [eventId]: reject([...attendees], (attendee) => attendee.memberId === member.memberId),
  };
};

const eventAttendees = (state = {}, action) => {
  switch (action.type) {
    case CLUB_EVENT_RECEIVE: {
      const { eventId, attendees } = action.payload;
      return {
        ...state,
        [eventId]: attendees,
      };
    }

    case CLUB_EVENT_SET_ATTENDANCE_REQUEST: {
      const { eventId, isAttending, member } = action.payload;
      return isAttending
        ? addEventAttendee(state, eventId, member)
        : removeEventAttendee(state, eventId, member);
    }
    default:
      return state;
  }
};

const updateEventAttendance = (state, eventId, isAttending, memberId) => {
  const existingEventAttendances = get(state, eventId);
  if (!existingEventAttendances) {
    return state;
  }

  const attendance = find(existingEventAttendances, { memberId });
  if (!attendance) {
    return state;
  }

  return {
    ...state,
    [eventId]: uniqBy(
      [
        {
          ...attendance,
          isAttending,
        },
        ...existingEventAttendances,
      ],
      'memberId',
    ),
  };
};

const eventAttendances = (state = {}, action) => {
  switch (action.type) {
    case CLUB_EVENT_ATTENDANCE_RECEIVE: {
      const { eventId, attendances } = action.payload;
      return {
        ...state,
        [eventId]: attendances,
      };
    }
    case CLUB_EVENT_SET_ATTENDANCE_REQUEST: {
      const {
        eventId,
        isAttending,
        member: { memberId },
      } = action.payload;
      return updateEventAttendance(state, eventId, isAttending, memberId);
    }
    case CLUB_EVENT_SET_ATTENDANCE_FAILURE: {
      const {
        eventId,
        isAttending,
        member: { memberId },
      } = action.payload;
      return updateEventAttendance(state, eventId, !isAttending, memberId);
    }
    default:
      return state;
  }
};

const loadingAttendances = (state = [], action) => {
  switch (action.type) {
    case CLUB_EVENT_ATTENDANCE_REQUEST: {
      const { eventId } = action.payload;
      return [...state, eventId];
    }
    case CLUB_EVENT_ATTENDANCE_FAILURE:
    case CLUB_EVENT_ATTENDANCE_RECEIVE: {
      const { eventId } = action.payload;
      const eventIds = [...state];
      return reject(eventIds, (loadingId) => loadingId === eventId);
    }
    default:
      return state;
  }
};

const loadedAttendances = (state = [], action) => {
  switch (action.type) {
    case CLUB_EVENT_ATTENDANCE_RECEIVE:
    case CLUB_EVENT_ATTENDANCE_FAILURE: {
      return [...state, action.payload.eventId];
    }
    default:
      return state;
  }
};

export default combineReducers({
  eventAttendances,
  eventAttendees,
  loadingEvents,
  loadingAttendances,
  loadedAttendances,
  hasAttemptedLoad,
  events,
});
