export default {
  avatar: {
    radius: 'circle',
    preset: 'default',
    size: 48,
  },
  icon: {
    inline: false,
    preset: 'body--small',
    radius: 'circle',
    size: 21,
  },
  image: {
    radius: 'rounded',
    height: 9,
    width: 16,
  },
  typography: {
    inline: false,
    radius: null,
    lineHeight: 21,
    preset: 'body--small',
    size: 14,
    width: '100%',
  },
};
