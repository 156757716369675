import { Cushion, FlexContainer, Grid, GridItem, Show, Typography } from '@pitchero/react-ui';
import PropTypes from 'prop-types';
import React from 'react';
import ClubLink from '../../club-link';
import CLUB_PROP_TYPES from '../../../lib/prop-types/club';
import NavBar from './nav-bar';

// Shows the title bar as a grid on desktop, as flex with conditional logic for title on mobile
const TitleBar = ({ children, club, showMobileTitle, isMobile, activeSection }) => (
  <>
    {isMobile ? (
      <FlexContainer flexDirection="row-reverse" justifyContent="space-between" alignItems="center">
        {children}
        {showMobileTitle && (
          <ClubLink currentClub={club} route="homepage">
            <a>
              <Typography
                component="h1"
                preset="headerClubName"
                color="headerContrast"
                style={{ hyphens: 'initial', maxWidth: '75%' }}
              >
                {club.name}
              </Typography>
            </a>
          </ClubLink>
        )}
      </FlexContainer>
    ) : (
      <Show minWidth="fullHeader" component="div">
        <Grid
          columns="1fr auto"
          alignItems="center"
          justifyItems="space-between"
          className="club-header__title-bar"
        >
          <FlexContainer flexDirection="column">
            <Cushion left="small" bottom="xxsmall" component="div">
              <ClubLink currentClub={club} route="homepage">
                <a>
                  <Typography
                    component="h1"
                    preset="headerClubName"
                    color="headerContrast"
                    style={{ hyphens: 'initial' }}
                  >
                    {club.name}
                  </Typography>
                </a>
              </ClubLink>
            </Cushion>
            <NavBar activeSection={activeSection} club={club} />
          </FlexContainer>
          <GridItem justifyItems="self-end">{children}</GridItem>
        </Grid>
      </Show>
    )}
  </>
);

TitleBar.propTypes = {
  club: CLUB_PROP_TYPES.isRequired,
  showMobileTitle: PropTypes.bool,
  children: PropTypes.node.isRequired,
  isMobile: PropTypes.bool.isRequired,
  activeSection: PropTypes.string,
};

TitleBar.defaultProps = {
  activeSection: '',
};

TitleBar.defaultProps = {
  showMobileTitle: false,
};

export default TitleBar;
