import { combineReducers } from 'redux';
import { groupBy, head, map, reject } from 'lodash';
import {
  SHOP_ASSIGNMENTS_REQUEST,
  SHOP_ASSIGNMENTS_RECEIVE,
  SHOP_ASSIGNMENTS_FAILURE,
  SHOP_CATEGORY_PAGE_REQUEST,
  SHOP_CATEGORY_PAGE_RECEIVE,
  SHOP_CATEGORY_PAGE_FAILURE,
  SHOP_LANDING_PAGE_REQUEST,
  SHOP_LANDING_PAGE_RECEIVE,
  SHOP_LANDING_PAGE_FAILURE,
  SHOP_PRODUCT_PAGE_REQUEST,
  SHOP_PRODUCT_PAGE_RECEIVE,
  SHOP_PRODUCT_PAGE_FAILURE,
  SHOP_BANNER_CLOSED_TOGGLED,
} from '../action-types';

const isLoadingLandingPage = (state = false, action) => {
  switch (action.type) {
    case SHOP_LANDING_PAGE_REQUEST:
      return true;
    case SHOP_LANDING_PAGE_RECEIVE:
    case SHOP_LANDING_PAGE_FAILURE: {
      return false;
    }
    default:
      return state;
  }
};

const hasLoadingLandingPage = (state = false, action) => {
  switch (action.type) {
    case SHOP_LANDING_PAGE_REQUEST:
      return false;
    case SHOP_LANDING_PAGE_RECEIVE:
      return true;
    default:
      return state;
  }
};

const featuredProducts = (state = [], action) => {
  switch (action.type) {
    case SHOP_LANDING_PAGE_RECEIVE: {
      const { categories } = action.payload;
      return categories;
    }
    default:
      return state;
  }
};

const landingPageLoadingError = (state = null, action) => {
  switch (action.type) {
    case SHOP_LANDING_PAGE_REQUEST:
      return null;
    case SHOP_LANDING_PAGE_FAILURE: {
      return action.payload;
    }
    default:
      return state;
  }
};

const currentlyLoadingCategoryPages = (state = [], action) => {
  switch (action.type) {
    case SHOP_CATEGORY_PAGE_REQUEST: {
      const { category, page } = action.payload;
      return [...state, `${category}-${page}`];
    }
    case SHOP_CATEGORY_PAGE_RECEIVE:
    case SHOP_CATEGORY_PAGE_FAILURE: {
      const categories = [...state];
      const { category, page } = action.payload;
      return reject(categories, (loadingId) => loadingId === `${category}-${page}`);
    }
    default:
      return state;
  }
};

const loadedCategoryPages = (state = [], action) => {
  switch (action.type) {
    case SHOP_CATEGORY_PAGE_RECEIVE: {
      const { category, page } = action.payload;
      return [...state, `${category}-${page}`];
    }
    default:
      return state;
  }
};

const paginatedProductLists = (state = {}, action) => {
  switch (action.type) {
    case SHOP_CATEGORY_PAGE_RECEIVE: {
      const { category, products: loadedProducts, page } = action.payload;
      const key = `${category}-${page}`;
      return {
        ...state,
        [key]: loadedProducts,
      };
    }

    default:
      return state;
  }
};

const categoryPagination = (state = {}, action) => {
  switch (action.type) {
    case SHOP_CATEGORY_PAGE_RECEIVE: {
      const { category, pagination } = action.payload;
      return {
        ...state,
        [category]: pagination,
      };
    }

    default:
      return state;
  }
};

const categoryLoadingErrors = (state = [], action) => {
  switch (action.type) {
    case SHOP_CATEGORY_PAGE_REQUEST: {
      const { category, page } = action.payload;
      return reject([...state], { category, page });
    }
    case SHOP_CATEGORY_PAGE_FAILURE: {
      return [state, action.payload];
    }
    default:
      return state;
  }
};

const currentlyLoadingProducts = (state = [], action) => {
  switch (action.type) {
    case SHOP_PRODUCT_PAGE_REQUEST: {
      const { productId } = action.payload;
      return [...state, productId];
    }
    case SHOP_PRODUCT_PAGE_RECEIVE:
    case SHOP_PRODUCT_PAGE_FAILURE: {
      const products = [...state];
      const { productId } = action.payload;
      return reject(products, (loadingId) => loadingId === productId);
    }
    default:
      return state;
  }
};

const loadedProductPages = (state = [], action) => {
  switch (action.type) {
    case SHOP_PRODUCT_PAGE_RECEIVE: {
      const { productId } = action.payload;
      return [...state, productId];
    }
    default:
      return state;
  }
};

const productLoadingErrors = (state = [], action) => {
  switch (action.type) {
    case SHOP_PRODUCT_PAGE_REQUEST: {
      const { productId } = action.payload;
      return reject([...state], { productId });
    }
    case SHOP_PRODUCT_PAGE_FAILURE: {
      return [state, action.payload];
    }
    default:
      return state;
  }
};

const productPages = (state = {}, action) => {
  switch (action.type) {
    case SHOP_PRODUCT_PAGE_RECEIVE: {
      const { productId, product } = action.payload;
      return {
        ...state,
        [productId]: product,
      };
    }

    default:
      return state;
  }
};

const overdueProducts = (state = [], action) => {
  switch (action.type) {
    case SHOP_ASSIGNMENTS_RECEIVE: {
      const { data } = action.payload;
      const assignmentsByProduct = groupBy(data, 'productId');
      return map(assignmentsByProduct, (productAssignments) => {
        const { product } = head(productAssignments);
        return {
          ...product,
          assignments: productAssignments.map((assignment) => {
            const { product: productToDiscard, ...otherProps } = assignment;
            return {
              ...otherProps,
            };
          }),
        };
      });
    }

    default:
      return state;
  }
};

const assignments = (state = [], action) => {
  switch (action.type) {
    case SHOP_ASSIGNMENTS_RECEIVE: {
      const { data } = action.payload;
      return data.map((assignment) => {
        const { product, ...otherProps } = assignment;
        return {
          ...otherProps,
        };
      });
    }

    default:
      return state;
  }
};

const isLoadingAssignments = (state = false, action) => {
  switch (action.type) {
    case SHOP_ASSIGNMENTS_REQUEST:
      return true;
    case SHOP_ASSIGNMENTS_FAILURE:
    case SHOP_ASSIGNMENTS_RECEIVE: {
      return false;
    }

    default:
      return state;
  }
};

const hasLoadedAssignments = (state = false, action) => {
  switch (action.type) {
    case SHOP_ASSIGNMENTS_FAILURE:
    case SHOP_ASSIGNMENTS_RECEIVE: {
      return true;
    }

    default:
      return state;
  }
};

const bannerClosed = (state = false, action) => {
  switch (action.type) {
    case SHOP_BANNER_CLOSED_TOGGLED: {
      return action.payload;
    }

    default:
      return state;
  }
};

export default combineReducers({
  assignments,
  bannerClosed,
  categoryLoadingErrors,
  categoryPagination,
  currentlyLoadingCategoryPages,
  currentlyLoadingProducts,
  featuredProducts,
  hasLoadedAssignments,
  hasLoadingLandingPage,
  isLoadingAssignments,
  isLoadingLandingPage,
  landingPageLoadingError,
  loadedCategoryPages,
  loadedProductPages,
  overdueProducts,
  paginatedProductLists,
  productLoadingErrors,
  productPages,
});
