import React from 'react';
import Head from 'next/head';
import { Cushion, Typography } from '@pitchero/react-ui';
import { Trans } from '../../lib/i18n';
import CLUB_PROP_TYPES from '../../lib/prop-types/club';
import ErrorLinks from './error-links';

const Club404 = ({ club }) => (
  <>
    <Head>
      <title>Page not found</title>
    </Head>
    <Cushion bottom="xxlarge">
      <Cushion top="xlarge" responsive={[{ maxWidth: 'palm', props: { top: 'medium' } }]}>
        <Typography preset="errorCode" component="div" align="center" color="alto">
          404
        </Typography>
      </Cushion>
      <Cushion horizontal="medium">
        <Typography component="div" align="center" preset="staticSubtitle--large">
          <Trans i18nKey="common:page_not_found_main_message">
            Oops, we cant find the page youre looking for
          </Trans>
        </Typography>
      </Cushion>
      <Cushion top="medium" horizontal="medium" bottom="medium">
        <Typography align="center" component="div" preset="body--large" color="dustygrey">
          <Trans i18nKey="common:page_not_found_follow_links">
            Please check your URL or use one of the links below
          </Trans>
        </Typography>
      </Cushion>
      <ErrorLinks club={club} />
    </Cushion>
  </>
);

Club404.propTypes = {
  club: CLUB_PROP_TYPES.isRequired,
};

export default Club404;
