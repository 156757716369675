import { combineReducers } from 'redux';
import { find, get, includes, reject, uniqBy } from 'lodash';
import {
  MATCH_CENTRE_PAGE_REQUEST,
  MATCH_CENTRE_PAGE_RECEIVE,
  MATCH_CENTRE_PAGE_FAILURE,
  MATCH_CENTRE_UPDATES_RECEIVE,
  MATCH_CENTRE_UPDATES_VIEW,
} from '../../action-types';

const loadingPages = (state = [], action) => {
  switch (action.type) {
    case MATCH_CENTRE_PAGE_REQUEST: {
      const { teamId, fixtureId } = action.payload;
      return [...state, `${teamId}-${fixtureId}`];
    }
    case MATCH_CENTRE_PAGE_RECEIVE:
    case MATCH_CENTRE_PAGE_FAILURE: {
      const { teamId, fixtureId } = action.payload;
      const matchCentreIds = [...state];
      return reject(matchCentreIds, (loadingId) => loadingId === `${teamId}-${fixtureId}`);
    }
    default:
      return state;
  }
};

const loadedPages = (state = [], action) => {
  switch (action.type) {
    case MATCH_CENTRE_PAGE_RECEIVE: {
      const { teamId, fixtureId } = action.payload;
      return [...state, `${teamId}-${fixtureId}`];
    }
    default:
      return state;
  }
};

const addUpdatesToPageData = (pageData, overview, receivedEventsByPeriod) => {
  const { eventsByPeriod: currentEventsByPeriod } = pageData;
  const newEventsByPeriod = [];

  const updatedEventsByPeriod = [...currentEventsByPeriod].map((period) => {
    const receivedPeriod = find(receivedEventsByPeriod, { number: period.number });
    if (!receivedPeriod) {
      return period;
    }

    const currentEvents = get(period, 'events', []);
    const currentEventIds = currentEvents.map((event) => event.id);

    const receivedEvents = get(receivedPeriod, 'events', []);
    // newEvents are ones that we have just received and were not in the existing array
    const newEvents = receivedEvents.filter((event) => !includes(currentEventIds, event.id));
    // updatedEvents are ones that we already have but may have amended data
    const updatedEvents = uniqBy([...receivedEvents, ...currentEvents], 'id').filter((event) =>
      includes(currentEventIds, event.id),
    );

    newEventsByPeriod.push({
      ...receivedPeriod,
      events: newEvents,
    });

    return {
      ...receivedPeriod,
      events: updatedEvents,
    };
  });

  const currentPeriodNumbers = currentEventsByPeriod.map((period) => period.number);
  const newPeriods = receivedEventsByPeriod.filter(
    (period) => !includes(currentPeriodNumbers, period.number),
  );

  return {
    ...pageData,
    overview,
    eventsByPeriod: updatedEventsByPeriod,
    newEventsByPeriod: [...newEventsByPeriod, ...newPeriods],
  };
};

const showEventUpdates = (pageData) => {
  const { newEventsByPeriod, eventsByPeriod } = pageData;

  const updatedEventsByPeriod = [...eventsByPeriod].map((period) => {
    const newPeriod = find(newEventsByPeriod, { number: period.number });
    if (!newPeriod) {
      return period;
    }
    const existingEvents = get(period, 'events', []);
    const newEvents = get(newPeriod, 'events', []);

    return {
      ...newPeriod,
      events: uniqBy([...newEvents, ...existingEvents], 'id'),
    };
  });

  const currentPeriodNumbers = eventsByPeriod.map((period) => period.number);
  const newPeriods = newEventsByPeriod.filter(
    (period) => !includes(currentPeriodNumbers, period.number),
  );

  return {
    ...pageData,
    eventsByPeriod: [...updatedEventsByPeriod, ...newPeriods],
    newEventsByPeriod: [],
  };
};

const pageData = (state = {}, action) => {
  switch (action.type) {
    case MATCH_CENTRE_PAGE_RECEIVE: {
      const { teamId, fixtureId } = action.payload;
      return {
        ...state,
        [`${teamId}-${fixtureId}`]: action.payload.data,
      };
    }
    case MATCH_CENTRE_UPDATES_RECEIVE: {
      const { teamId, fixtureId, overview, eventsByPeriod } = action.payload;
      const matchData = get(state, `${teamId}-${fixtureId}`);
      if (!matchData) {
        return state;
      }

      return {
        ...state,
        [`${teamId}-${fixtureId}`]: addUpdatesToPageData(matchData, overview, eventsByPeriod),
      };
    }
    case MATCH_CENTRE_UPDATES_VIEW: {
      const { teamId, fixtureId } = action.payload;
      const matchData = get(state, `${teamId}-${fixtureId}`);
      if (!matchData) {
        return state;
      }

      return {
        ...state,
        [`${teamId}-${fixtureId}`]: showEventUpdates(matchData),
      };
    }
    default:
      return state;
  }
};

const pageLoadingErrors = (state = {}, action) => {
  switch (action.type) {
    case MATCH_CENTRE_PAGE_REQUEST: {
      const { teamId, fixtureId } = action.payload;
      const errors = { ...state };
      delete errors[`${teamId}-${fixtureId}`];
      return errors;
    }
    case MATCH_CENTRE_PAGE_FAILURE: {
      const { teamId, fixtureId } = action.payload;
      return {
        ...state,
        [`${teamId}-${fixtureId}`]: action.payload,
      };
    }
    default:
      return state;
  }
};

export default combineReducers({
  loadingPages,
  loadedPages,
  pageData,
  pageLoadingErrors,
});
