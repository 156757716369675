import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Image from './index';

const LazyImage = ({
  alt,
  backgroundColor,
  className,
  height,
  includeRetina,
  quality,
  src,
  style,
  transformation,
  width,
}) => {
  const [srcToUse, setSrc] = useState('https://images.pitchero.com/up/spacer.gif');
  const ref = useRef();
  useEffect(() => {
    if ('IntersectionObserver' in window && ref.current) {
      const lazyImageObserver = new IntersectionObserver((entries, observer) => {
        entries.forEach((entry) => {
          const { isIntersecting } = entry;
          if (isIntersecting) {
            setSrc(src);
            observer.disconnect();
          }
        });
      });

      lazyImageObserver.observe(ref.current);
    } else {
      // No IntersectionObserver, just use the src
      setSrc(src);
    }
  }, []);

  return (
    <Image
      alt={alt}
      backgroundColor={backgroundColor}
      className={className}
      height={height}
      includeRetina={includeRetina}
      quality={quality}
      src={srcToUse}
      ref={ref}
      style={style}
      transformation={transformation}
      width={width}
      loading="lazy"
    />
  );
};

LazyImage.defaultProps = {
  backgroundColor: null,
  className: null,
  includeRetina: false,
  quality: null,
  transformation: 'fit',
  style: null,
};

LazyImage.propTypes = {
  alt: PropTypes.string.isRequired,
  backgroundColor: PropTypes.string,
  className: PropTypes.string,
  height: PropTypes.number.isRequired,
  includeRetina: PropTypes.bool,
  quality: PropTypes.number,
  src: PropTypes.string.isRequired,
  style: PropTypes.shape(),
  transformation: PropTypes.string,
  width: PropTypes.number.isRequired,
};

export default LazyImage;
