import { Button, Cushion, FlexContainer, Grid, Space, Typography } from '@pitchero/react-ui';
import UserIcon from '@pitchero/react-ui/dist/cjs/components/Icons/User';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Trans } from '../../../../lib/i18n';
import TokenRedirect from '../../../token-redirect-link/route';
import { isLoggedIn } from '../../../../store/auth/selectors';
import { isAssociated, isLoadingNetworkPanel } from '../../../../store/membership/selectors';
import AccountLink from './account-link';

const Login = ({
  isLoading,
  isAssociated: isAssociatedToClub,
  loggedIn,
  style: customStyle,
  openPanel,
  onClose,
}) => {
  if (isLoading || loggedIn) {
    return (
      <Cushion horizontal="medium" vertical="medium" component="div">
        <AccountLink isLoading={isLoading} onClose={onClose} />
      </Cushion>
    );
  }

  return (
    <Space horizontal="medium" vertical="medium" style={{ textAlign: 'center', ...customStyle }}>
      <Grid columns="1fr 1fr" columnGap="medium">
        {!isLoading && !isAssociatedToClub && (
          <TokenRedirect route="join">
            <Button theme="solidWhite" fullWidth>
              <Typography weight="bold" color="black" transform="uppercase">
                <Trans i18nKey="common:join">Join</Trans>
              </Typography>
            </Button>
          </TokenRedirect>
        )}
        {!isLoading && !loggedIn && (
          <Button onClick={openPanel} theme="hollowWhite">
            <FlexContainer alignItems="center" justifyContent="center">
              <Cushion horizontal="xsmall">
                <UserIcon color="white" size={20} />
              </Cushion>
              <Typography weight="bold" color="white" transform="uppercase">
                <Trans i18nKey="common:login">Login</Trans>
              </Typography>
            </FlexContainer>
          </Button>
        )}
      </Grid>
    </Space>
  );
};

Login.propTypes = {
  isAssociated: PropTypes.bool,
  isLoading: PropTypes.bool,
  loggedIn: PropTypes.bool,
  openPanel: PropTypes.func.isRequired,
  style: PropTypes.shape(),
  onClose: PropTypes.func.isRequired,
};

Login.defaultProps = {
  loggedIn: false,
  isLoading: false,
  isAssociated: false,
  style: {},
};

const mapStateToProps = (state, { club: { id: clubId } }) => ({
  loggedIn: isLoggedIn(state),
  isLoading: isLoadingNetworkPanel(state),
  isAssociated: isAssociated(state, clubId),
});

export default connect(mapStateToProps)(Login);
