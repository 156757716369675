import { Button, Cushion, FlexContainer, Hide, Show, withTheme } from '@pitchero/react-ui';
import CrossIcon from '@pitchero/react-ui/dist/cjs/components/Icons/Cross';
import MenuIcon from '@pitchero/react-ui/dist/cjs/components/Icons/Menu';
import UserIcon from '@pitchero/react-ui/dist/cjs/components/Icons/User';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Trans } from '../../../lib/i18n';
import TokenRedirect from '../../token-redirect-link/route';
import IconWithTheme from '../../icon-with-theme';
import { openLoginPanel as openLoginPanelAction } from '../../../store/auth/actions';
import { isLoggedIn } from '../../../store/auth/selectors';
import {
  getLoggedInUser,
  isAssociated as isAssociatedSelector,
  isLoadingNetworkPanel,
} from '../../../store/membership/selectors';
import AccountLink from './account-link';

const LoginBox = ({
  isAssociated,
  isLoading,
  isMenuOpen,
  isLoginOpen,
  loggedIn,
  onMenuClick,
  openLoginPanel,
  openMobileLoginPanel,
  user,
}) => (
  <FlexContainer
    alignItems="center"
    justifyContent="flex-end"
    flexWrap="wrap"
    style={{ gap: '10px' }}
  >
    {!isLoading && !isAssociated && !loggedIn && (
      <Show minWidth="fullHeader" component="div">
        <TokenRedirect route="join">
          <Button theme="hollowWhite" style={{ borderColor: 'black', backgroundColor: 'black' }}>
            <Trans i18nKey="common:join">Join</Trans>
          </Button>
        </TokenRedirect>
      </Show>
    )}
    {!isLoading && !loggedIn && (
      <>
        <Show minWidth="fullHeader" component="div">
          <Button
            className="login-button"
            onClick={openLoginPanel}
            theme="headerContrastText"
            style={{
              borderRadius: '10px',
              border: '2px solid',
              borderColor: 'primaryContrast',
              color: 'primaryContrast',
              padding: '12px 18px',
            }}
          >
            <FlexContainer alignItems="center">
              <Cushion right="xsmall">
                <UserIcon size={20} color="headerContrast" />
              </Cushion>
              <Trans i18nKey="common:login">Login</Trans>
            </FlexContainer>
          </Button>
        </Show>
        {!isMenuOpen && !isLoginOpen && (
          <Show maxWidth="fullHeader" component="div">
            <Button
              className="login-button"
              onClick={openMobileLoginPanel}
              theme="headerContrastText"
              style={{ whiteSpace: 'nowrap' }}
            >
              <FlexContainer alignItems="center">
                <Cushion right="xsmall">
                  <UserIcon size={20} color="headerContrast" />
                </Cushion>
                <Trans i18nKey="common:login">Login</Trans>
              </FlexContainer>
            </Button>
          </Show>
        )}
      </>
    )}
    {(user || isLoading) && !isMenuOpen && (
      <Cushion
        className="logged-in-avatar"
        component="div"
        horizontal="medium"
        responsive={[{ minWidth: 'fullHeader', props: { horizontal: '0px' } }]}
      >
        <AccountLink isLoading={isLoading} />
      </Cushion>
    )}
    <Hide minWidth="fullHeader" component="div">
      <FlexContainer>
        <Cushion vertical="small" right="small">
          <IconWithTheme>
            {isMenuOpen || isLoginOpen ? (
              <CrossIcon
                color="headerContrast"
                onClick={onMenuClick}
                style={{ cursor: 'pointer' }}
                size={26}
              />
            ) : (
              <MenuIcon
                color="headerContrast"
                onClick={onMenuClick}
                style={{ cursor: 'pointer' }}
                size={26}
              />
            )}
          </IconWithTheme>
        </Cushion>
      </FlexContainer>
    </Hide>
  </FlexContainer>
);

LoginBox.propTypes = {
  isAssociated: PropTypes.bool,
  isLoading: PropTypes.bool,
  isMenuOpen: PropTypes.bool,
  isLoginOpen: PropTypes.bool,
  loggedIn: PropTypes.bool,
  openLoginPanel: PropTypes.func.isRequired,
  openMobileLoginPanel: PropTypes.func,
  onMenuClick: PropTypes.func.isRequired,
  user: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    avatar: PropTypes.string,
  }),
};

LoginBox.defaultProps = {
  loggedIn: false,
  isLoading: false,
  isAssociated: false,
  isMenuOpen: false,
  isLoginOpen: false,
  user: null,
  openMobileLoginPanel: () => null,
};

const dispatchToProps = {
  openLoginPanel: openLoginPanelAction,
};

const mapStateToProps = (state, { club: { id: clubId } }) => ({
  loggedIn: isLoggedIn(state),
  isLoading: isLoadingNetworkPanel(state),
  isAssociated: isAssociatedSelector(state, clubId),
  user: getLoggedInUser(state),
});

export default connect(mapStateToProps, dispatchToProps)(withTheme(LoginBox));
