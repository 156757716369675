import { combineReducers } from 'redux';
import { reject } from 'lodash';
import {
  CLUB_INFORMATION_REQUEST,
  CLUB_INFORMATION_RECEIVE,
  CLUB_INFORMATION_FAILURE,
} from '../action-types';

const hasLoaded = (state = [], action) => {
  switch (action.type) {
    case CLUB_INFORMATION_RECEIVE:
      return [...state, action.payload.clubId];
    case CLUB_INFORMATION_REQUEST:
    case CLUB_INFORMATION_FAILURE: {
      const clubIds = [...state];
      return reject(clubIds, (clubId) => clubId === action.payload.clubId);
    }
    default:
      return state;
  }
};

const loadingClubs = (state = [], action) => {
  switch (action.type) {
    case CLUB_INFORMATION_REQUEST:
      return [...state, action.payload.clubId];
    case CLUB_INFORMATION_RECEIVE:
    case CLUB_INFORMATION_FAILURE: {
      const clubIds = [...state];
      return reject(clubIds, (clubId) => clubId === action.payload.clubId);
    }
    default:
      return state;
  }
};

const pageData = (state = {}, action) => {
  switch (action.type) {
    case CLUB_INFORMATION_RECEIVE: {
      return {
        ...state,
        [action.payload.clubId]: action.payload.pageData,
      };
    }
    default:
      return state;
  }
};

export default combineReducers({
  hasLoaded,
  loadingClubs,
  pageData,
});
