import PropTypes from 'prop-types';

const DROPDOWN_OPTIONS_PROPTYPES = PropTypes.arrayOf(
  PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    name: PropTypes.node,
    transKey: PropTypes.string,
    routeParams: PropTypes.shape({
      route: PropTypes.string.isRequired,
    }).isRequired,
  }),
);

export default DROPDOWN_OPTIONS_PROPTYPES;
