class ApiRequestException extends Error {
  constructor(message, statusCode, url, response) {
    super(message);
    this.name = 'ApiRequestException';
    this.errorStatusCode = statusCode;
    this.url = url;
    this.response = response;
  }
}

export default ApiRequestException;
