import { combineReducers } from 'redux';
import { reject } from 'lodash';
import {
  CLUB_ALBUM_REQUEST,
  CLUB_ALBUM_RECEIVE,
  CLUB_ALBUM_FAILURE,
  ALBUM_LIST_RECEIVE,
  ALBUM_LIST_FAILURE,
  ADD_COMMENT_SUCCESS,
  DELETE_COMMENT_SUCCESS,
  RESTORE_COMMENT_SUCCESS,
} from '../../action-types';

const loadingAlbums = (state = [], action) => {
  switch (action.type) {
    case CLUB_ALBUM_REQUEST: {
      return [...state, action.payload.albumId];
    }
    case CLUB_ALBUM_RECEIVE:
    case CLUB_ALBUM_FAILURE: {
      const albumIds = [...state];
      return reject(albumIds, (loadingId) => loadingId === action.payload.albumId);
    }
    default:
      return state;
  }
};

const increaseImageCommentCount = (state, imageId) =>
  [...state].map((album) => ({
    ...album,
    images: album.images.map((image) => ({
      ...image,
      commentCount: image.id === imageId ? image.commentCount + 1 : image.commentCount,
    })),
  }));

const decreaseImageCommentCount = (state, imageId) =>
  [...state].map((album) => ({
    ...album,
    images: album.images.map((image) => ({
      ...image,
      commentCount: image.id === imageId ? image.commentCount - 1 : image.commentCount,
    })),
  }));

const albums = (state = [], action) => {
  switch (action.type) {
    case CLUB_ALBUM_RECEIVE: {
      return [...state, action.payload.album];
    }
    case ADD_COMMENT_SUCCESS:
    case RESTORE_COMMENT_SUCCESS: {
      const { mediaType, mediaId } = action.payload;
      if (mediaType === 'image') {
        return increaseImageCommentCount(state, mediaId);
      }

      return state;
    }
    case DELETE_COMMENT_SUCCESS: {
      const { mediaType, mediaId } = action.payload;
      if (mediaType === 'image') {
        return decreaseImageCommentCount(state, mediaId);
      }

      return state;
    }
    default:
      return state;
  }
};

const albumLoadingErrors = (state = [], action) => {
  switch (action.type) {
    case CLUB_ALBUM_REQUEST: {
      const loadErrors = [...state];
      return reject(loadErrors, (loadError) => loadError.albumId === action.payload.albumId);
    }
    case CLUB_ALBUM_FAILURE: {
      return [...state, action.payload];
    }
    default:
      return state;
  }
};

const albumLists = (state = {}, action) => {
  switch (action.type) {
    case ALBUM_LIST_RECEIVE: {
      const { albums: loadedAlbums, page, search, teamId } = action.payload;
      const key = search ? `${teamId}-${page}-${search}` : `${teamId}-${page}`;
      return {
        ...state,
        [key]: loadedAlbums,
      };
    }

    default:
      return state;
  }
};

const loadingErrors = (state = {}, action) => {
  switch (action.type) {
    case ALBUM_LIST_RECEIVE: {
      const { page, search, teamId } = action.payload;
      const key = search ? `${teamId}-${page}-${search}` : `${teamId}-${page}`;
      const errors = { ...state };
      delete errors[key];
      return errors;
    }
    case ALBUM_LIST_FAILURE: {
      const { page, search, teamId } = action.payload;
      const key = search ? `${teamId}-${page}-${search}` : `${teamId}-${page}`;
      return {
        ...state,
        [key]: action.payload,
      };
    }

    default:
      return state;
  }
};

const pagination = (state = {}, action) => {
  switch (action.type) {
    case ALBUM_LIST_RECEIVE: {
      const { teamId = 0, pagination: teamPagination, search } = action.payload;
      const key = search ? `${teamId}-${search}` : teamId;
      return {
        ...state,
        [key]: teamPagination,
      };
    }
    default:
      return state;
  }
};

export default combineReducers({
  loadingAlbums,
  albumLoadingErrors,
  albums,
  albumLists,
  loadingErrors,
  pagination,
});
