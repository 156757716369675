import {
  DRAFT_THEME_REQUEST,
  DRAFT_THEME_RECEIVE,
  DRAFT_THEME_FAILURE,
  DRAFT_THEME_CLEAR,
  SELECT_SEASON,
} from '../action-types';

export const exitThemePreview = () => async (dispatch) => {
  dispatch({ type: DRAFT_THEME_CLEAR });
};

export const fetchThemeDraft = (club, draftId) => async (dispatch) => {
  try {
    dispatch({ type: DRAFT_THEME_REQUEST, payload: { club, draftId } });
    const { makeIsomorphicApiRequest } = await import('../../lib/api-proxy');
    const response = await makeIsomorphicApiRequest(
      `v2/club-website/${club.id}/theme/draft/${draftId}`,
    );

    if (response.status !== 200) {
      dispatch({ type: DRAFT_THEME_FAILURE, payload: { club, draftId } });
      return;
    }

    const data = await response.json();
    dispatch({ type: DRAFT_THEME_RECEIVE, payload: { club, draftId, data } });
  } catch (error) {
    dispatch({ type: DRAFT_THEME_FAILURE, payload: { club, draftId, error } });
  }
};

export const selectSeasonAction = (seasonId) => async (dispatch) => {
  dispatch({ type: SELECT_SEASON, payload: seasonId });
};
