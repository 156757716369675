import React from 'react';
import PropTypes from 'prop-types';
import { isEqual } from 'lodash';
import { Cushion, Typography } from '@pitchero/react-ui';
import { Trans } from '../../lib/i18n';
import useBreakpoint from '../../lib/use-breakpoint';
import Sponsor from './sponsor';
import SponsorsMobile from './mobile';

const Sponsors = ({ className, titleTranslationKey, sponsors, style }) => {
  const isMobile = useBreakpoint();
  if (isMobile) {
    return <SponsorsMobile sponsors={sponsors} sponsorsPerPage={8} />;
  }

  return (
    <Cushion
      className={className}
      horizontal="small"
      vertical="xlarge"
      component="div"
      responsive={[{ minWidth: 'tab', horizontal: 'large' }]}
      style={style}
    >
      <Cushion bottom="large">
        <Typography preset="title" color="black" component="h4" align="center">
          <Trans i18nKey={titleTranslationKey}>{titleTranslationKey}</Trans>
        </Typography>
      </Cushion>
      <div style={{ textAlign: 'center' }}>
        {sponsors.map((sponsor) => (
          <div key={sponsor.id} style={{ display: 'inline-block', padding: '0 24px 36px' }}>
            <Sponsor {...sponsor} />
          </div>
        ))}
      </div>
    </Cushion>
  );
};

Sponsors.defaultProps = {
  className: '',
  style: {},
};

Sponsors.propTypes = {
  className: PropTypes.string,
  titleTranslationKey: PropTypes.string.isRequired,
  sponsors: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  style: PropTypes.shape(),
};

const propsAreEqual = (prevProps, nextProps) => {
  const { sponsors: oldSponsors, titleTranslationKey: oldTitle } = prevProps;
  const { sponsors: newSponsors, titleTranslationKey: newTitle } = nextProps;
  return oldTitle === newTitle && isEqual(oldSponsors, newSponsors);
};

export default React.memo(Sponsors, propsAreEqual);
