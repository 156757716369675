import avatar from './avatar';
import breakpoints from './breakpoints';
import borders from './borders';
import button from './button';
import colors from './colors';
import grid from './grid';
import image from './image';
import panel from './panel';
import radii from './radii';
import shadows from './shadows';
import shimmer from './shimmer';
import spacing from './spacing';
import switchObj from './switch';
import typography from './typography';
import icon from './icon';

export default function (club) {
  const {
    colours: {
      primary,
      primaryContrast,
      primaryDark,
      primaryDarkContrast,
      primaryLight,
      primaryLightContrast,
      secondary,
      secondaryContrast,
      secondaryLight,
      headerBgMobile,
      footerBg,
      footerBgContrast,
    },
  } = club;

  const themeColours = colors(
    primary,
    primaryContrast,
    primaryDark,
    primaryDarkContrast,
    primaryLight,
    primaryLightContrast,
    secondary,
    secondaryContrast,
    secondaryLight,
    headerBgMobile,
    footerBg,
    footerBgContrast,
  );

  return {
    avatar,
    borders: borders(themeColours),
    breakpoints,
    button: button(themeColours),
    colors: themeColours,
    grid,
    icon,
    image,
    panel,
    radii,
    shadows,
    shimmer,
    spacing,
    switch: switchObj,
    typography,
  };
}
