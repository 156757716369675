import React from 'react';
import { Cushion, Typography } from '@pitchero/react-ui';
import ShieldImportantIcon from '@pitchero/react-ui/dist/cjs/components/Icons/ShieldImportant';
import Head from 'next/head';
import { string } from 'prop-types';
import { Trans } from '../../lib/i18n';
import IconWithTheme from '../icon-with-theme';
import { appDomain } from '../../lib/env';

const ExpiredPackage = (props) => {
  const { clubFolder } = props;

  return (
    <Cushion
      style={{ textAlign: 'center' }}
      top="xlarge"
      bottom="xxlarge"
      responsive={[{ minWidth: 'palm', props: { top: 'xxxlarge' } }]}
    >
      <Head>
        <title>Package Expired</title>
      </Head>
      <IconWithTheme>
        <ShieldImportantIcon color="mercury" size={95} />
      </IconWithTheme>
      <Cushion bottom="medium" top="medium" horizontal="medium">
        <Typography component="div" preset="staticSubtitle--large">
          <Trans i18nKey="common:package_expired_title">
            Website cannot be accessed via this domain
          </Trans>
        </Typography>
      </Cushion>
      <Cushion horizontal="medium" top="small">
        <Typography component="div" preset="body--large" color="dustygrey">
          <Trans i18nKey="common:package_expired_text">
            This club no longer subscribes to a package that supports the use of a domain name.
          </Trans>
        </Typography>
      </Cushion>

      <Cushion horizontal="medium" top="small">
        <Typography component="div" preset="body--large" color="dustygrey">
          <Trans i18nKey="common:package_expired_folder_text" values={{ folder: clubFolder }}>
            You can still access the page content via this URL:
          </Trans>
          {` `}
          <a style={{ color: '#366F8D' }} href={`https://${appDomain()}/clubs/${clubFolder}`}>
            <Trans
              i18nKey="common:package_expired_folder_text_url"
              values={{ folder: clubFolder, appDomain: appDomain() }}
            >
              {`www.${appDomain()}/clubs/${clubFolder}`}
            </Trans>
          </a>
        </Typography>
      </Cushion>

      <Cushion horizontal="medium" top="small">
        <Typography component="div" preset="body--large" color="dustygrey">
          <Trans i18nKey="common:package_expired_admin">
            If you are a club administrator or webmaster, you can re-activate this feature via the
            Club Control Panel.
          </Trans>
        </Typography>
      </Cushion>
    </Cushion>
  );
};

ExpiredPackage.propTypes = {
  clubFolder: string.isRequired,
};

export default ExpiredPackage;
