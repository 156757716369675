export default function ({
  primary,
  primaryContrast,
  primaryLight,
  primaryLightContrast,
  secondary,
  secondaryContrast,
  secondaryLight,
}) {
  const primaryTheme = {
    backgroundColor: primary,
    color: primaryContrast,
    ':hover': {
      backgroundColor: primaryLight,
      color: primaryLightContrast,
    },
    boxShadow: 'rgba(0, 0, 0, 0.2) 0px 3px 10px 0px',
  };

  const primaryContrastTheme = {
    backgroundColor: primaryContrast,
    color: primaryContrast === '#FFFFFF' ? '#222' : '#FFF',
  };

  const primaryContrastTextTheme = {
    backgroundColor: 'transparent',
    color: primaryContrast,
  };

  const button = {
    themes: {
      primary: primaryTheme,
      primaryText: {
        backgroundColor: 'transparent',
        color: primary,
      },
      primaryContrast: primaryContrastTheme,
      primaryContrastText: primaryContrastTextTheme,
      headerContrast: primaryContrastTheme,
      headerContrastText: primaryContrastTextTheme,
      featuredContentCta: primaryContrastTheme,
      secondary: {
        backgroundColor: secondary,
        color: secondaryContrast,
        ':hover': {
          backgroundColor: secondaryLight,
        },
      },
      mercury: {
        backgroundColor: '#e7e7e7',
        color: '#333',
      },
      text: {
        backgroundColor: 'transparent',
        marginBottom: 0,
        marginLeft: 0,
        marginRight: 0,
        marginTop: 0,
        paddingBottom: 0,
        paddingLeft: 0,
        paddingRight: 0,
        paddingTop: 0,
        textAlign: 'inherit',
      },
      textWithBorder: {
        border: '2px solid #D4D4D4',
      },
      solidWhite: {
        border: '2px solid white',
        borderRadius: '10px',
        backgroundColor: 'white',
        color: 'black',
      },
      hollowWhite: {
        border: '2px solid white',
        borderRadius: '10px',
        backgroundColor: 'transparent',
        color: 'white',
      },
      positive: {
        border: '2px solid #00D296',
        borderRadius: '10px',
        backgroundColor: '#00D296',
        color: 'white',
      },
    },
    presets: {
      base: {
        display: 'inline-block',
        position: 'relative',
        marginBottom: 0,
        marginLeft: 0,
        marginRight: 0,
        marginTop: 0,
        paddingBottom: 12,
        paddingLeft: 24,
        paddingRight: 24,
        paddingTop: 12,
        borderRadius: 3,
        fill: 'currentColor',
        transition: 'background-color 0.5s ease',
        outline: 0,
        cursor: 'pointer',
        border: 'none',
      },
      disabled: {
        opacity: 0.2,
        cursor: 'not-allowed',
      },
      fullWidth: {
        width: '100%',
      },
      compact: {
        paddingBottom: 8,
        paddingLeft: 22,
        paddingRight: 22,
        paddingTop: 8,
      },
    },
  };

  return button;
}
