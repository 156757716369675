export const PLACEHOLDER_AVATAR = 'https://images.pitchero.com/holders/new-200x200.png';

export default {
  presets: {
    default: {
      backgroundColor: 'white',
      borderColor: 'white',
      radius: 'circle',
      borderStyle: 'solid',
      borderWidth: 3,
      boxShadow: 'avatar',
      size: 48,
    },
  },
};
