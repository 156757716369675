import { combineReducers } from 'redux';
import {
  LOGIN_PANEL_OPEN,
  LOGIN_PANEL_CLOSE,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  PASSWORD_RESET_REQUEST,
  PASSWORD_RESET_SUCCESS,
  PASSWORD_RESET_FAILURE,
  USER_REQUEST,
  USER_RECEIVE,
  USER_FAILURE,
  UNAUTHORISED_RESPONSE,
  NETWORK_PANEL_REQUEST_SUCCESS,
} from '../action-types';

const isAttemptingLogin = (state = false, action) => {
  switch (action.type) {
    case LOGIN_REQUEST:
      return true;
    case LOGIN_SUCCESS:
    case LOGIN_FAILURE:
      return false;
    default:
      return state;
  }
};

const isLoggedIn = (state = false, action) => {
  switch (action.type) {
    case LOGIN_SUCCESS:
      return true;
    case UNAUTHORISED_RESPONSE:
    case LOGIN_FAILURE:
      return false;
    default:
      return state;
  }
};

const hasBeenLoggedIn = (state = false, action) => {
  switch (action.type) {
    case LOGIN_SUCCESS:
      return true;
    default:
      return state;
  }
};

const loginFailed = (state = false, action) => {
  switch (action.type) {
    case LOGIN_SUCCESS:
    case LOGIN_REQUEST:
      return false;
    case LOGIN_FAILURE:
      return true;
    default:
      return state;
  }
};

const isRetrievingUser = (state = false, action) => {
  switch (action.type) {
    case USER_REQUEST:
      return true;
    case USER_RECEIVE:
    case USER_FAILURE:
      return false;
    default:
      return state;
  }
};

const loginStatus = (state = null, action) => {
  switch (action.type) {
    case LOGIN_FAILURE:
      if (action.payload === undefined || action.payload.status === undefined) {
        return 500;
      }

      return action.payload.status;
    default:
      return state;
  }
};

const loggedInUser = (state = null, action) => {
  switch (action.type) {
    case USER_RECEIVE:
      return action.payload.user;
    default:
      return state;
  }
};

const panelOpen = (state = false, action) => {
  switch (action.type) {
    case LOGIN_PANEL_OPEN:
      return true;
    case LOGIN_PANEL_CLOSE:
      return false;
    default:
      return state;
  }
};

const isSubmittingPasswordReset = (state = false, action) => {
  switch (action.type) {
    case PASSWORD_RESET_REQUEST:
      return true;
    case PASSWORD_RESET_SUCCESS:
    case PASSWORD_RESET_FAILURE:
      return false;
    default:
      return state;
  }
};

const hasCheckedLoginState = (state = false, action) => {
  switch (action.type) {
    case NETWORK_PANEL_REQUEST_SUCCESS:
      return true;
    default:
      return state;
  }
};

const passwordResetFailed = (state = false, action) => {
  switch (action.type) {
    case PASSWORD_RESET_FAILURE:
      return true;
    default:
      return state;
  }
};

const passwordResetStatus = (state = null, action) => {
  switch (action.type) {
    case PASSWORD_RESET_FAILURE:
      if (action.payload === undefined || action.payload.status === undefined) {
        return 500;
      }

      return action.payload.status;
    default:
      return state;
  }
};

export default combineReducers({
  hasBeenLoggedIn,
  isAttemptingLogin,
  isLoggedIn,
  loginStatus,
  loginFailed,
  panelOpen,
  isRetrievingUser,
  loggedInUser,
  hasCheckedLoginState,
  isSubmittingPasswordReset,
  passwordResetFailed,
  passwordResetStatus,
});
