import ClubRouter from './club-router';
import routes from './routes';

/**
 * Export a single instance of the club router with routes loaded from routes.js
 */
const routerInstance = new ClubRouter();
routes.forEach((route) => routerInstance.add(route));

module.exports = routerInstance;
