/* eslint-disable func-names */
import App from 'next/app';
import React from 'react';
import { Provider } from 'react-redux';
import Router from 'next/router';
import { register as registerServiceWorker } from 'next-offline/runtime';
import withReduxStore from '../lib/with-redux-store';
import ClubFrame from '../components/club-frame';
import HeaderProvider from '../components/club-frame/header/provider';
import { fetchThemeDraft } from '../store/clubs/actions';
import { getCurrentClub } from '../store/clubs/selectors';
import { getIsApp } from '../store/common/selectors';
import { appWithTranslation } from '../lib/i18n';
import ClubThemeProvider from '../lib/club-theme/provider';
import ClubLoadingFailure from '../components/errors/club-loading-failure';
import Club404 from '../components/errors/club-404';
import ClubNotFound from '../components/errors/club-not-found';
import ClubNotLive from '../components/errors/club-not-live';
import GenericError from '../components/errors/generic';
import ClubWebsiteNotLiveException from '../exceptions/club-website-not-live-exception';
import PitcheroFrame from '../components/pitchero-frame';
import UserDataLayerManager from '../components/user-data-layer-manager';
import GlobalLogin from '../components/auth/global-login';
import NetworkPanelDataLoader from '../components/network-panel/data-loader';
import AdTrackingController from '../components/adverts/ad-tracking-controller';
import UnableToAccessUsingExternalDomainException from '../exceptions/unable-to-access-using-external-domain-exception';
import PackageExpired from '../components/errors/package-expired';

const captureException = async (error, ctx) => {
  const sentry = await import('../lib/sentry');
  const { captureException: sentryCapture } = sentry.default();
  return sentryCapture(error, ctx);
};

class ClubWebsiteApp extends App {
  constructor(...args) {
    super(...args);
    this.state = {
      hasError: false,
      errorEventId: undefined,
      userManager: { user: null, hasCheckedLoginState: false },
      dataLayerRetries: 0,
    };
  }

  static async getInitialProps({ Component, ctx }) {
    let club;
    let pageProps = {};
    let componentInitialProps = {};

    try {
      if (ctx.isClient) {
        const reduxState = ctx.reduxStore.getState();
        club = getCurrentClub(reduxState);
        ctx.club = club;
        pageProps.club = club;
        pageProps.frameless = getIsApp(reduxState);

        if (typeof Component.getInitialProps === 'function' && club) {
          componentInitialProps = await Component.getInitialProps(ctx);
          pageProps = {
            ...pageProps,
            ...componentInitialProps,
          };
        }
        return { pageProps, club, path: ctx.asPath };
      }

      if (ctx.req.query.app === 'club') {
        ctx.req.session.isApp = true;
        ctx.req.session.save();
        pageProps.frameless = true;
      } else {
        pageProps.frameless = ctx.req.session.isApp;
      }

      let { clubLoadingError } = ctx.req;

      const {
        club: requestClub,
        headers: { 'user-agent': userAgent },
      } = ctx.req;

      if (requestClub) {
        club = requestClub;
        ctx.club = club;
        pageProps.club = club;

        if (!club.live) {
          clubLoadingError = new ClubWebsiteNotLiveException();
        }
      }

      const domainDoesNotMatch = process.env.APP_DOMAIN !== ctx.req.headers.host;
      const notTesting = !process.env.INTEGRATION_TESTING;

      // Ensure clubs that don't have the ability to use an external domain are shown a package expired page
      if (!club.canUseExternalDomain && domainDoesNotMatch && notTesting) {
        clubLoadingError = new UnableToAccessUsingExternalDomainException();
      }

      // Check if we need to load in a draft theme for previewing
      const themeDraftId = ctx.req.query.preview_theme;
      if (club && themeDraftId) {
        await ctx.reduxStore.dispatch(fetchThemeDraft(club, themeDraftId));
        pageProps.themeDraftId = themeDraftId;
      }

      if (typeof Component.getInitialProps === 'function' && club) {
        componentInitialProps = await Component.getInitialProps(ctx);
        pageProps = {
          ...pageProps,
          ...componentInitialProps,
        };
      }
      return { pageProps, club, clubLoadingError, userAgent, path: ctx.asPath };
    } catch (error) {
      // Capture errors that happen during a page's getInitialProps.
      // This will work on both client and server sides.
      const errorEventId = await captureException(error, ctx);

      return {
        club,
        pageProps,
        hasError: true,
        errorEventId,
      };
    }
  }

  static getDerivedStateFromProps(props, state) {
    // If there was an error generated within getInitialProps, and we haven't
    // yet seen an error, we add it to this.state here
    return {
      hasError: props.hasError || state.hasError || false,
      errorEventId: props.errorEventId || state.errorEventId || undefined,
    };
  }

  static getDerivedStateFromError() {
    // React Error Boundary here allows us to set state flagging the error (and
    // later render a fallback UI).
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    const errorEventId = captureException(error, { errorInfo });

    // Store the event id at this point as we don't have access to it within
    // `getDerivedStateFromError`.
    this.setState({ errorEventId });
  }

  /**
   * Fire a page view event on the initial render
   */
  componentDidMount() {
    this.firePageViewEvent();

    function refreshAdsFromBiddingScript() {
      if (window.BIDDINGSTACK) {
        window.BIDDINGSTACK.api.teardown();
        window.BIDDINGSTACK.api.bootstrap();
      }
    }

    let adRefreshReattemptTime = 200;

    function checkAdsAndRefreshBiddingScript() {
      if (document.getElementsByClassName('js-ad').length === 0) {
        adRefreshReattemptTime += 200;

        // max delay of 4 seconds before stopping ( if there's large amounts of content to load )
        if (adRefreshReattemptTime < 4000) {
          setTimeout(() => {
            checkAdsAndRefreshBiddingScript();
          }, adRefreshReattemptTime);
        }
      } else {
        refreshAdsFromBiddingScript();
      }
    }

    Router.events.on('routeChangeComplete', () => {
      this.firePageViewEvent();
      checkAdsAndRefreshBiddingScript();
    });

    registerServiceWorker();

    // Polyfill to cover .remove in the recaptcha
    // from:https://github.com/jserz/js_piece/blob/master/DOM/ChildNode/remove()/remove().md
    (function (arr) {
      arr.forEach((item) => {
        // eslint-disable-next-line no-prototype-builtins
        if (item.hasOwnProperty('remove')) {
          return;
        }
        Object.defineProperty(item, 'remove', {
          configurable: true,
          enumerable: true,
          writable: true,
          value: function remove() {
            this.parentNode.removeChild(this);
          },
        });
      });
    })([Element.prototype, CharacterData.prototype, DocumentType.prototype]);
  }

  gtmDataLayerExists = () => {
    // dataLayer variable exists
    if (typeof dataLayer !== 'undefined') {
      // method within this exists for pushing events
      if (typeof dataLayer.push === 'function') {
        return true;
      }
    }

    return false;
  };

  /* global dataLayer */
  /**
   * Update the dataLayer with the correct page_type before triggering the page view event
   */
  firePageViewEvent = () => {
    const {
      pageProps: { frameless, pageType, referringPlacement, contentId },
    } = this.props;

    const retryTimeDelay = 600; // wait 600ms to retry
    const retryAmount = 10; // retry 10 times

    // Has the datalayer loaded yet? If not, we need to wait until it does and retry
    // the fire page view event.
    if (this.gtmDataLayerExists()) {
      dataLayer.push({
        event: 'pageView',
        page_type: pageType,
        frameless: frameless ? 1 : 0,
        referring_placement: referringPlacement,
        content_id: contentId,
      });
    } else {
      // GTM DataLayer did not exist, attempting to load again..
      this.state.dataLayerRetries += 1;

      if (this.state.dataLayerRetries < retryAmount) {
        this.fireDelayedPageViewEvent(retryTimeDelay);
      }
    }
  };

  fireDelayedPageViewEvent = (time) => {
    setTimeout(() => this.firePageViewEvent(), time);
  };

  updateUserManager = (userManager) => {
    this.setState({ userManager });
  };

  renderClubLoadingError() {
    const { clubLoadingError, club } = this.props;

    // Using the .name as instanceof did not seem consistent between server and client.
    if (clubLoadingError.name === 'ClubNotFoundException') {
      return (
        <PitcheroFrame>
          <ClubNotFound />
        </PitcheroFrame>
      );
    }

    if (clubLoadingError.name === 'ClubWebsiteNotLiveException') {
      return (
        <PitcheroFrame>
          <ClubNotLive club={this.props.club} />
        </PitcheroFrame>
      );
    }

    if (clubLoadingError.name === 'ClubWebsitePageNotFoundException') {
      return (
        <ClubFrame club={club}>
          <Club404 club={club} />
        </ClubFrame>
      );
    }

    if (clubLoadingError.name === 'UnableToAccessUsingExternalDomainException') {
      return (
        <PitcheroFrame>
          <PackageExpired clubFolder={club.folder} />
        </PitcheroFrame>
      );
    }

    return <ClubLoadingFailure clubLoadingError={clubLoadingError} />;
  }

  isInternetExplorer = () => {
    if (typeof window === 'undefined') {
      return false;
    }

    return !!window.MSInputMethodContext && !!document.documentMode;
  };

  render() {
    const { Component, pageProps, reduxStore, clubLoadingError, club } = this.props;

    let pageContent;
    if (clubLoadingError) {
      pageContent = this.renderClubLoadingError();
    } else if (club) {
      if (this.state.hasError) {
        pageContent = (
          <ClubFrame>
            <GenericError eventId={this.state.errorEventId} />
          </ClubFrame>
        );
      } else if (pageProps.frameless) {
        pageContent = <Component {...pageProps} />;
      } else {
        pageContent = (
          <ClubFrame
            activeSection={pageProps.activeSection}
            activeSubSection={pageProps.activeSubSection}
            team={pageProps.team}
            themeDraftId={pageProps.themeDraftId}
            pageType={pageProps.pageType}
          >
            <AdTrackingController
              club={club}
              pageType={pageProps.pageType}
              // When there is a key the component will trigger a re-render which fetches the ads.
              // Some pages may navigate to another page of the same type, to fix this
              // we can optionally pass in a pageUid to trigger this re-render. PageUid should
              // include the pageType and then an id e.g. newsArticle12345
              key={pageProps.pageUid ? pageProps.pageUid : pageProps.pageType}
            />
            <Component {...pageProps} />
          </ClubFrame>
        );
      }
    } else {
      pageContent = (
        <PitcheroFrame>
          <ClubNotFound />
        </PitcheroFrame>
      );
    }

    const ie11StyleSheet = this.isInternetExplorer() ? (
      <link rel="stylesheet" href="/static/ie11overrides.css" />
    ) : null;

    return (
      <>
        {ie11StyleSheet}
        <Provider store={reduxStore}>
          <ClubThemeProvider>
            <HeaderProvider>
              <UserDataLayerManager />
              {pageContent}
              <GlobalLogin />
              <NetworkPanelDataLoader />
            </HeaderProvider>
          </ClubThemeProvider>
        </Provider>
      </>
    );
  }
}

export default appWithTranslation(withReduxStore(ClubWebsiteApp));
