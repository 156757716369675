import ClubRouter from './index';
import sanitizeParams from './sanitize-params';
import { appDomain } from '../env';

const clubUrl = (club, route, params = {}) => {
  const cleanedParams = sanitizeParams(params);
  const { as } = ClubRouter.findAndGetUrls(route, cleanedParams).urls;
  if (club.externalDomain) {
    return `https://${club.externalDomain}${as}`;
  }

  return `https://${appDomain()}/clubs/${club.folder}${as}`;
};

export default clubUrl;
