import React from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider } from '@pitchero/react-ui';
import { connect } from 'react-redux';
import { getClubTheme } from '../../store/clubs/selectors';

const ClubThemeProvider = ({ children, theme }) => (
  <ThemeProvider theme={theme} wrapperName="cwjsApp">
    {children}
  </ThemeProvider>
);

ClubThemeProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  theme: PropTypes.shape().isRequired,
};

const mapStateToProps = (state) => ({
  theme: getClubTheme(state),
});

export default connect(mapStateToProps)(ClubThemeProvider);
