export default {
  presets: {
    default: {
      checkedOptions: {
        color: 'dovegrey',
        fill: 'mercury',
        toggle: {
          color: 'black',
          fill: 'white',
          shadow: 'switch',
        },
      },
      uncheckedOptions: {
        color: 'dovegrey',
        fill: 'mercury',
        toggle: {
          color: 'black',
          fill: 'white',
          shadow: 'switch',
        },
      },
    },
    success: {
      checkedOptions: {
        color: 'white',
        fill: 'positive',
        toggle: {
          color: 'black',
          fill: 'white',
          shadow: 'switch',
        },
      },
      uncheckedOptions: {
        color: 'dovegrey',
        fill: 'mercury',
        toggle: {
          color: 'black',
          fill: 'white',
          shadow: 'switch',
        },
      },
    },
    primaryDark: {
      checkedOptions: {
        color: 'primaryDarkContrast',
        fill: 'primaryDark',
        toggle: {
          color: 'black',
          fill: 'white',
          shadow: 'switch',
        },
      },
      uncheckedOptions: {
        color: 'primaryDarkContrast',
        fill: 'primaryDark',
        toggle: {
          color: 'black',
          fill: 'white',
          shadow: 'switch',
        },
      },
    },
  },
};
