import { combineReducers } from 'redux';
import { PAGE_DATA_LOAD } from '../action-types';

const lastLoadedPageData = (state = null, action) => {
  switch (action.type) {
    case PAGE_DATA_LOAD:
      return action.payload;
    default:
      return state;
  }
};

export default combineReducers({ lastLoadedPageData });
