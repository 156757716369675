import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { isLoggedIn } from '../../store/auth/selectors';
import {
  fetchNetworkPanelData,
  getNetworkPanelDataFromCache,
} from '../../store/membership/actions';
import { getLoggedInUser, isLoadingNetworkPanel } from '../../store/membership/selectors';

const NetworkPanelDataLoader = ({
  getNetworkPanelFromCache,
  fetchNetworkPanel,
  isLoading,
  loggedIn,
  user,
}) => {
  let updatesChannel;
  const registerBroadcastListener = () => {
    try {
      // The service worker will broadcast on the channel `network-panel` if it retrieves an updated
      // response from the API. If this happens then attempt to load this updated response from the cache
      updatesChannel = new BroadcastChannel('network-panel');
      updatesChannel.addEventListener('message', async () => {
        getNetworkPanelFromCache();
      });
    } catch (e) {
      // BroadcastChannel likely isn't supported by this browser.
    }
  };

  const closeBroadcastListener = () => {
    if (updatesChannel) {
      updatesChannel.close();
    }
  };

  useEffect(() => {
    // Register the event listener for the broadcastChannel on mount, close it on unmount.
    registerBroadcastListener();
    return closeBroadcastListener;
  }, []);

  useEffect(() => {
    // Attempt to get the network panel if we don't have a user but are logged in
    if (!user && loggedIn && !isLoading) {
      fetchNetworkPanel();
    }
  }, [loggedIn]);

  return null;
};

NetworkPanelDataLoader.propTypes = {
  getNetworkPanelFromCache: PropTypes.func.isRequired,
  fetchNetworkPanel: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  loggedIn: PropTypes.bool.isRequired,
  user: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    avatar: PropTypes.string,
  }),
};

NetworkPanelDataLoader.defaultProps = {
  user: null,
};

const dispatchToProps = {
  getNetworkPanelFromCache: getNetworkPanelDataFromCache,
  fetchNetworkPanel: fetchNetworkPanelData,
};

const mapStateToProps = (state) => ({
  loggedIn: isLoggedIn(state),
  isLoading: isLoadingNetworkPanel(state),
  user: getLoggedInUser(state),
});

export default connect(mapStateToProps, dispatchToProps)(NetworkPanelDataLoader);
