export default {
  presets: {
    headline: {
      width: 300,
      height: 168,
      transformation: 'fit',
      outputFormat: 'jpg',
      responsive: [
        {
          minWidth: 'tab',
          props: {
            width: 980,
            height: 550,
            transformation: 'fit',
            outputFormat: 'jpg',
          },
        },
        {
          minWidth: 'palm',
          props: {
            width: 680,
            height: 382,
            transformation: 'fit',
            outputFormat: 'jpg',
          },
        },
      ],
    },
    card: {
      width: 320,
      height: 180,
      transformation: 'square',
      outputFormat: 'jpg',
      responsive: [
        {
          maxWidth: 'palm',
          props: {
            width: 160,
            height: 90,
            transformation: 'square',
            outputFormat: 'jpg',
          },
        },
      ],
    },
    'card--palm-large': {
      width: 320,
      height: 180,
      transformation: 'square',
      outputFormat: 'jpg',
      responsive: [
        {
          maxWidth: 'palm',
          props: {
            width: 640,
            height: 360,
            transformation: 'square',
            outputFormat: 'jpg',
          },
        },
      ],
    },
    'card--large': {
      width: 640,
      height: 360,
      transformation: 'square',
      outputFormat: 'jpg',
      responsive: [
        {
          maxWidth: 'palm',
          props: {
            width: 320,
            height: 180,
            transformation: 'square',
            outputFormat: 'jpg',
          },
        },
      ],
    },
    comment: {
      width: 320,
      height: 180,
      transformation: 'square',
    },
    sponsor: {
      width: 320,
      height: 138,
      transformation: 'frame',
      backgroundColor: '#ffffff',
    },
    mediaItem: {
      width: 180,
      height: 101,
      transformation: 'square',
      outputFormat: 'jpg',
    },
    photoSquare: {
      width: 200,
      height: 200,
      transformation: 'square',
      outputFormat: 'jpg',
    },
    fullWidthAndHeight: {
      width: '100%',
      height: '100%',
    },
    photoPortrait: {
      width: 100,
      height: 100,
      transformation: 'square',
      outputFormat: 'jpg',
      responsive: [
        {
          minWidth: 'palm',
          props: {
            width: 235,
            height: 235,
            transformation: 'square',
            outputFormat: 'jpg',
          },
        },
      ],
    },
    photoLandscape: {
      width: 356,
      height: 235,
      transformation: 'square',
      outputFormat: 'jpg',
    },
    teamPhotoPanel: {
      width: 272,
      height: 153,
      transformation: 'fit',
      outputFormat: 'jpg',
    },
    profile: {
      width: 120,
      height: 120,
      transformation: 'square',
      outputFormat: 'jpg',
      responsive: [
        {
          minWidth: 'tab',
          props: {
            width: 240,
            height: 240,
            transformation: 'square',
            outputFormat: 'jpg',
          },
        },
      ],
    },
    productCard: {
      width: 90,
      height: 90,
      backgroundColor: '#ffffff',
      transformation: 'frame',
      fullWidth: true,
      outputFormat: 'jpg',
      responsive: [
        {
          minWidth: 'tab',
          props: {
            width: 350,
            height: 350,
            fullWidth: true,
            transformation: 'frame',
            backgroundColor: '#ffffff',
            outputFormat: 'jpg',
          },
        },
      ],
    },
    'productCard--landscape': {
      width: 90,
      height: 90,
      backgroundColor: '#ffffff',
      transformation: 'square',
      outputFormat: 'jpg',
    },
    'productCard--portrait': {
      width: 350,
      height: 350,
      backgroundColor: '#ffffff',
      fullWidth: true,
      transformation: 'square',
      outputFormat: 'jpg',
    },
  },
};
