import React from 'react';
import PropTypes from 'prop-types';
import { withTheme } from '@pitchero/react-ui';
import { get } from 'lodash';
import styled, { keyframes } from 'styled-components';

const loadingAnimation = keyframes`
  0% { left: 50%; width: 0; z-index: 100; },
  33%{left:0;width:'100%';z-index:10;}
  100% { left: 0; width: 100%; },
`;

const LoadingBarContainer = styled.div`
  position: relative;
  width: 100%;
  height: 3px;
`;

const LoadingBarInner = styled.div`
  content: '';
  display: inline;
  position: absolute;
  width: 0;
  height: 100%;
  left: 50%;
  text-align: center;
  animation: ${loadingAnimation} 2s linear infinite;
  z-index: 1;
`;

const LoadingBar = ({ background, color, isLoading, uiTheme }) => {
  const primaryColor = get(uiTheme, `colors[${color}]`);
  const backgroundColor = get(uiTheme, `colors[${background}]`);
  if (isLoading) {
    return (
      <LoadingBarContainer style={{ backgroundColor }}>
        <LoadingBarInner style={{ backgroundColor, animationDelay: '1s' }} />
        <LoadingBarInner style={{ backgroundColor: primaryColor }} />
        <LoadingBarInner style={{ backgroundColor, animationDelay: '1s' }} />
      </LoadingBarContainer>
    );
  }

  return <div style={{ height: 3 }} />;
};

LoadingBar.defaultProps = {
  background: 'mercury',
  color: 'primary',
  isLoading: false,
};

LoadingBar.propTypes = {
  background: PropTypes.string,
  color: PropTypes.string,
  isLoading: PropTypes.bool,
  uiTheme: PropTypes.shape().isRequired,
};

export default withTheme(LoadingBar);
