import { combineReducers } from 'redux';
import { keyBy } from 'lodash';
import {
  ACTIVITY_STREAM_REQUEST,
  ACTIVITY_STREAM_RECEIVE,
  ACTIVITY_STREAM_FAILURE,
  RELATED_CONTENT_REQUEST,
  RELATED_CONTENT_RECEIVE,
  RELATED_CONTENT_FAILURE,
} from '../action-types';

const loading = (state = false, action) => {
  switch (action.type) {
    case ACTIVITY_STREAM_REQUEST:
      return true;
    case ACTIVITY_STREAM_RECEIVE:
    case ACTIVITY_STREAM_FAILURE:
      return false;
    default:
      return state;
  }
};

const loaded = (state = [], action) => {
  switch (action.type) {
    case ACTIVITY_STREAM_RECEIVE:
    case ACTIVITY_STREAM_FAILURE:
      return [...state, action.payload.key];
    default:
      return state;
  }
};

const messages = (state = {}, action) => {
  switch (action.type) {
    case ACTIVITY_STREAM_RECEIVE: {
      const newMessages = keyBy(action.payload.messages, 'id');
      return {
        ...state,
        ...newMessages,
      };
    }
    default:
      return state;
  }
};

const relatedContent = (state = {}, action) => {
  switch (action.type) {
    case RELATED_CONTENT_RECEIVE: {
      const { data, messageId } = action.payload;
      return {
        ...state,
        [messageId]: data,
      };
    }
    default:
      return state;
  }
};

const loadedRelatedContent = (state = [], action) => {
  switch (action.type) {
    case RELATED_CONTENT_FAILURE:
    case RELATED_CONTENT_RECEIVE: {
      const { messageId } = action.payload;
      return [...state, messageId];
    }
    default:
      return state;
  }
};

const relatedContentLoading = (state = false, action) => {
  switch (action.type) {
    case RELATED_CONTENT_REQUEST:
      return true;
    case RELATED_CONTENT_FAILURE:
    case RELATED_CONTENT_RECEIVE:
      return false;
    default:
      return state;
  }
};

export default combineReducers({
  loading,
  loaded,
  messages,
  relatedContent,
  relatedContentLoading,
  loadedRelatedContent,
});
