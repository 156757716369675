import React from 'react';
import { Cushion, Grid, MaxWidth } from '@pitchero/react-ui';
import PropTypes from 'prop-types';
import Image from '../../basic-image';

const LeagueSponsor = ({ sponsor }) => (
  <a href={sponsor.link}>
    <Cushion style={{ backgroundColor: sponsor.backgroundColour }} component="div" vertical="small">
      <Grid className="league-affiliation-banner-wrapper">
        <Cushion
          style={{ justifySelf: 'center', height: '40px' }}
          component="div"
          className="league-affiliation-banner-image"
          horizontal="small"
        >
          <MaxWidth component="div" maxWidth={400} center>
            <Image src={sponsor.image} alt={sponsor.name} width={400} height={40} />
          </MaxWidth>
        </Cushion>
      </Grid>
    </Cushion>
  </a>
);

LeagueSponsor.propTypes = {
  sponsor: PropTypes.shape({
    link: PropTypes.string.isRequired,
    backgroundColour: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
  }),
};

LeagueSponsor.defaultProps = {
  sponsor: null,
};

export default LeagueSponsor;
