import React from 'react';
import PropTypes from 'prop-types';
import { Cushion, Typography, withTheme } from '@pitchero/react-ui';
import ArrowDownIcon from '@pitchero/react-ui/dist/cjs/components/Icons/ArrowDown';
import { get } from 'lodash';
import styled from 'styled-components';
import ClubLink from '../../../club-link';
import IconWithTheme from '../../../icon-with-theme';
import Dropdown from './dropdown';

const ActiveIndicator = styled.div`
  text-decoration: ${(props) =>
    props.isActive ? `underline 4px ${props.primaryContrast}` : 'none'};
  text-underline-offset: 10px;
  &:hover {
    text-decoration: ${(props) => `underline 4px ${props.primaryContrast}`};
  }
`;

class NavItem extends React.PureComponent {
  render() {
    const {
      activeSection,
      children,
      options,
      groupedOptions,
      routeParams,
      style: customStyle,
      getIsActive,
      id,
      uiTheme,
      navBarLeftOffset,
    } = this.props;

    const primaryContrast = get(uiTheme, 'colors.primaryContrast', '#fff');
    const hasOptions = !!options || !!groupedOptions;

    const content = (
      <Cushion vertical="small" horizontal="small" component="div">
        {children}
        {hasOptions && (
          <Cushion left="xxsmall">
            <IconWithTheme>
              <ArrowDownIcon topOffset={2} size={13} />
            </IconWithTheme>
          </Cushion>
        )}
      </Cushion>
    );

    if (hasOptions || groupedOptions) {
      return (
        <Dropdown
          options={options}
          groupedOptions={groupedOptions}
          navBarLeftOffset={navBarLeftOffset}
        >
          {content}
        </Dropdown>
      );
    }

    return (
      <ClubLink {...routeParams}>
        <a className={`primary-nav__${id}`}>
          <ActiveIndicator
            isActive={getIsActive(activeSection)}
            primaryContrast={primaryContrast}
            color={primaryContrast}
            style={customStyle}
          >
            <Typography preset="header--large" color="headerContrast">
              {content}
            </Typography>
          </ActiveIndicator>
        </a>
      </ClubLink>
    );
  }
}

NavItem.propTypes = {
  activeSection: PropTypes.string,
  children: PropTypes.node.isRequired,
  id: PropTypes.string,
  getIsActive: PropTypes.func,
  style: PropTypes.shape(),
  options: PropTypes.arrayOf(PropTypes.shape()),
  groupedOptions: PropTypes.arrayOf(PropTypes.shape()),
  routeParams: PropTypes.shape(),
  uiTheme: PropTypes.shape(),
  navBarLeftOffset: PropTypes.number.isRequired,
};

NavItem.defaultProps = {
  activeSection: null,
  getIsActive: () => false,
  id: null,
  style: {},
  options: null,
  groupedOptions: null,
  routeParams: {},
  uiTheme: {},
};
export default withTheme(NavItem);
