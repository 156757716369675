import { FlexContainer, withTheme } from '@pitchero/react-ui';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { Trans } from '../../../../lib/i18n';
import getNavItems from '../nav-items';
import NavItem from './nav-item';

const NavBar = ({ activeSection, club }) => {
  const navItems = getNavItems({ activeSection, club, limitTeams: true, groupMore: true });
  const [navBarLeftOffset, setNavBarLeftOffset] = useState(0);
  const navBarRef = useRef(null);

  useEffect(() => {
    const newLeftOffset = navBarRef.current ? navBarRef.current.getBoundingClientRect().left : 0;
    if (navBarLeftOffset !== newLeftOffset) {
      setNavBarLeftOffset(newLeftOffset);
    }
  }, []);

  return (
    <div ref={navBarRef}>
      <FlexContainer alignItems="center" justifyContent="flex-start">
        {Object.values(navItems).map((navItem) => (
          <div key={navItem.id}>
            <NavItem
              {...navItem}
              activeSection={activeSection}
              navBarLeftOffset={navBarLeftOffset}
              style={{ whiteSpace: 'nowrap' }}
            >
              {navItem.transKey ? (
                <Trans i18nKey={navItem.transKey}>{navItem.transKey}</Trans>
              ) : (
                navItem.name
              )}
            </NavItem>
          </div>
        ))}
      </FlexContainer>
    </div>
  );
};

NavBar.defaultProps = {
  activeSection: null,
};

NavBar.propTypes = {
  activeSection: PropTypes.string,
  club: PropTypes.shape({
    paymentsEnabled: PropTypes.bool,
    teamSections: PropTypes.arrayOf(PropTypes.shape()),
  }).isRequired,
};

export default withTheme(NavBar);
