import { combineReducers } from 'redux';
import { reject } from 'lodash';
import {
  SPONSORED_ARTICLE_REQUEST,
  SPONSORED_ARTICLE_RECEIVE,
  SPONSORED_ARTICLE_FAILURE,
} from '../action-types';

const loadingArticles = (state = [], action) => {
  switch (action.type) {
    case SPONSORED_ARTICLE_REQUEST: {
      return [...state, action.payload.contentId];
    }
    case SPONSORED_ARTICLE_RECEIVE:
    case SPONSORED_ARTICLE_FAILURE: {
      const contentIds = [...state];
      return reject(contentIds, (loadingId) => loadingId === action.payload.contentId);
    }
    default:
      return state;
  }
};

const hasAttemptedLoad = (state = [], action) => {
  switch (action.type) {
    case SPONSORED_ARTICLE_RECEIVE:
    case SPONSORED_ARTICLE_FAILURE: {
      return [...state, action.payload.contentId];
    }
    default:
      return state;
  }
};

const articles = (state = [], action) => {
  switch (action.type) {
    case SPONSORED_ARTICLE_RECEIVE: {
      return [...state, action.payload.content];
    }
    default:
      return state;
  }
};

const sponsoredArticlePageLoadingError = (state = null, action) => {
  switch (action.type) {
    case SPONSORED_ARTICLE_RECEIVE: {
      const { contentId } = action.payload;
      return {
        ...state,
        [contentId]: false,
      };
    }
    case SPONSORED_ARTICLE_FAILURE: {
      const { contentId, clubId, statusCode, error } = action.payload;
      return {
        ...state,
        [contentId]: {
          clubId,
          statusCode,
          error,
        },
      };
    }
    default:
      return state;
  }
};

const loadedArticles = (state = [], action) => {
  switch (action.type) {
    case SPONSORED_ARTICLE_RECEIVE:
      return [...state, action.payload.contentId];
    default:
      return state;
  }
};

export default combineReducers({
  loadingArticles,
  hasAttemptedLoad,
  sponsoredArticlePageLoadingError,
  articles,
  loadedArticles,
});
