import { Grid, Shadow, Typography } from '@pitchero/react-ui';
import dynamic from 'next/dynamic';
import Head from 'next/head';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import AppAdBanner from '../external-scripts/app-ad-banner';
import TeamFrame from '../team-frame';
import generateClubCrestUrl from '../../lib/club-crest-url';
import { montserrat } from '../../lib/club-theme/typography';
import CLUB_PROP_TYPES from '../../lib/prop-types/club';
import TEAM_PROP_TYPES from '../../lib/prop-types/team';
import clubUrl from '../../lib/routing/club-url';
import { getCurrentClub } from '../../store/clubs/selectors';
import DesignBuilder from './design-builder';
import Footer from './footer';
import Header from './header';
import HeaderWrapper from './header/wrapper';

const LoginPanel = dynamic(() => import('../auth/login-panel'), {
  ssr: false,
});

const NetworkPanelWrapper = dynamic(() => import('./network-panel-wrapper'), {
  ssr: false,
});

const ClubFrame = ({
  activeSection,
  activeSubSection,
  club,
  children,
  pageType,
  themeDraftId,
  team,
}) => {
  const isFreePackage = club.advertisingTier === 1;

  const wrapWithTeamFrame = (content) => {
    if (!team || !activeSubSection) {
      return (
        <Shadow className="contentWrapper" component="div">
          {content}
        </Shadow>
      );
    }

    const teamSponsors = club.sponsors.filter((sponsor) =>
      sponsor.placements.includes(`team${team.id}`),
    );
    return (
      <TeamFrame
        team={team}
        sponsors={teamSponsors}
        activeSection={activeSubSection}
        isFreePackage={isFreePackage}
      >
        {content}
      </TeamFrame>
    );
  };

  const faviconSizes = [57, 64, 96];

  const [appAdBannerClosed, setAppAdBannerClosed] = useState(false);

  return (
    <>
      <Head>
        {faviconSizes.map((size) => (
          <link
            rel="icon"
            key={`favicon${size}`}
            type="image/png"
            href={generateClubCrestUrl(club, size)}
            sizes={`${size}x${size}`}
          />
        ))}
        <link rel="apple-touch-icon" href={generateClubCrestUrl(club, 192)} />
        <link rel="manifest" href={`${clubUrl(club, 'homepage')}manifest.json`} />
        <meta name="theme-color" content={club.colours.primary} />
      </Head>
      {themeDraftId && <DesignBuilder club={club} themeDraftId={themeDraftId} />}
      <Typography component="div" family={montserrat}>
        <Grid rows="auto 1fr auto" columns="minmax(0, 1fr)" style={{ minHeight: '100vh' }}>
          <Shadow>
            <AppAdBanner club={club} onClose={() => setAppAdBannerClosed(true)} />
            <HeaderWrapper>
              <Header
                club={club}
                activeSection={activeSection}
                pageType={pageType}
                team={team}
                appAdBannerClosed={appAdBannerClosed}
              />
            </HeaderWrapper>
          </Shadow>
          {wrapWithTeamFrame(<div>{children}</div>)}
          <Footer club={club} team={team} />
        </Grid>
        <LoginPanel />
        <NetworkPanelWrapper currentClub={club} />
      </Typography>
    </>
  );
};

ClubFrame.defaultProps = {
  activeSection: '',
  activeSubSection: null,
  pageType: '',
  team: null,
  themeDraftId: null,
};

ClubFrame.propTypes = {
  activeSection: PropTypes.string,
  activeSubSection: PropTypes.string,
  club: CLUB_PROP_TYPES.isRequired,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  pageType: PropTypes.string,
  team: TEAM_PROP_TYPES,
  themeDraftId: PropTypes.string,
};

const mapStateToProps = (state) => ({
  club: getCurrentClub(state),
});

export default connect(mapStateToProps)(ClubFrame);
